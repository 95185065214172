/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  ChangeEvent,
} from 'react';

import * as Yup from 'yup';
import { Container as GridContainer, Col } from 'react-grid-system';
import { useParams, useHistory } from 'react-router-dom';
import { Form, FormHandles, SubmitHandler } from '@unform/core';
import Input from '../../components/TegraInput';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import TegraDivider from '../../components/TegraDivider';
import Modal from 'react-modal';
import {
  Container,
  Content,
  ProfileBox,
  ProfileFilesBox,
  ProfileAvatar,
  RowDoc,
  MensagemSucesso,
  MensagemErro,
  Subtitle,
  Description,
  DadosItem,
  DadosItemAction,
  DadosItemContent,
  ErrorsContainer,
  DadosList,
  ProfileBoxActions,
  ProfileTabs,
  BoxTitle,
  InputWrapper,
  CloseButton,
  ImprimirMsg,
  ListaAction,
  ContainerSuperintendente,
} from './styles';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import editIcon from '../../assets/tegra-icon-edit.svg';
import TegraButton from '../../components/TegraButton';
import TegraSelect from '../../components/TegraSelect';
import TegraDocumentInput from '../../components/TegraDocumentInput';
import TegraInputWithMask from '../../components/TegraInputWithMask';
import api from '../../services/api';
import { useAuth, User } from '../../hooks/auth';
import { useLoader } from '../../hooks/loader';
import TegraInput from '../../components/TegraInput';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { ReactComponent as CloseIcon } from  '../../assets/tegra-icon-close.svg';
import { toast } from 'react-toastify';
import { padding } from 'polished';

const customStyles = {
  content: {
    width: 740,
    padding: 40,
    paddingTop: 40,
    top: '50%',
    left: '50%',
    right: 'auto',
    color: '#60605d',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

interface ICorretorSigavi {
  label: string;
  value: number;
  empresaSigavi: number;
  tx_equipe: string;
  tx_cargoSigavi: string;
}


interface Zona {
  id: number;
  nome: string;
  label: string;
  value: number;

}
interface ISelectOption {
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

interface IFormData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface ProfileData {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  type: string;
  fieldName: string;
  mask?: string;
  options?: ISelectOption[];
  tipo?: number | null;
  editing?: boolean;
}

interface ProfileFormData {
  dt_cadastro: string;
  dt_provisorio: string;
  dt_nascimento?: string;
  id_cidade: {
    value: number;
    label: string;
  };
  id_status: {
    value: number;
    label: string;
  };
  estado?: {
    value: number;
    label: string;
  };
  id_regional: {
    value: number;
    label: string;
  };
  id_tipoUsuario: {
    value: number;
    label: string;
  };
  // tx_apelido: string;
  tx_bairro: string;
  tx_responsavel: string;
  tx_responsavelCelular: string;
  tx_responsavelEmail: string;
  tx_responsavelTelefone: string;
  tx_cep: string;
  tx_creci: string;
  tx_documento: string;
  tx_email: string;
  tx_logradouro: string;
  tx_nome: string;
  tx_numero: string;
  tx_razaoSocial: string;
  tx_status: string;
}

interface ProfileFilesFormData {
  identificacao_doc: File;
  comprovante_doc: File;
  creci_doc: File;
}


const mockData = [
  // {
  //   name: 'Tipo parceiro',
  //   value: 'Lorem Ipsum',
  //   type: 'select',
  //   fieldName: 'tipo_parceiro',
  // },
  {
    name: 'Regional',
    value: 'Lorem Ipsum',
    type: 'select',
    fieldName: 'regional',
  },
  { name: 'Gerente', value: 'Gerente 1', type: 'select', fieldName: 'gerente' },
  {
    name: 'CNPJ',
    value: '000.000.000-00',
    type: 'text',
    fieldName: 'cnpj',
    mask: '999.999.999-99',
  },
  { name: 'CRECI', value: '(00) 00000 0000', type: 'text', fieldName: 'creci' },
  {
    name: 'Razão Social',
    value: '(00) 00000 0000',
    type: 'text',
    fieldName: 'razao_social',
    tipo: 1,
  },
  {
    name: 'Responsável',
    value: 'Lorem Ipsum',
    type: 'text',
    fieldName: 'responsavel',
    tipo: 1,
  },
  {
    name: 'E-mail do responsável',
    value: 'loremipsum@loremipsum.com.br',
    type: 'email',
    fieldName: 'responsavel_email',
    tipo: 1,
  },
  {
    name: 'Telefone do responsável',
    value: '00000-0000',
    type: 'tel',
    mask: '99999-9999',
    fieldName: 'responsavel_tel',
    tipo: 1,
  },
  {
    name: 'Celular do responsável',
    value: '00000-0000',
    type: 'tel',
    mask: '99999-9999',
    fieldName: 'responsavel_cel',
    tipo: 1,
  },
  {
    name: 'Logradouro',
    value: 'Lorem Ipsum',
    type: 'text',
    fieldName: 'logradouro',
  },
  { name: 'Número', value: '00000', type: 'number', fieldName: 'numero' },
  {
    name: 'Complemento',
    value: '00000',
    type: 'text',
    fieldName: 'complemento',
  },
  { name: 'Bairro', value: 'Lorem Ipsum', type: 'text', fieldName: 'bairro' },
  {
    name: 'CEP',
    value: '0000000-000',
    type: 'text',
    fieldName: 'cep',
    mask: '9999999-999',
  },
  { name: 'Estado', value: 'Lorem Ipsum', type: 'select', fieldName: 'estado' },
  { name: 'Cidade', value: 'Lorem Ipsum', type: 'select', fieldName: 'cidade' },
  {
    name: 'Status',
    value: '00/00/0000  00:00:00',
    type: 'select',
    fieldName: 'status',
  },
  {
    name: 'Provisório até:',
    value: '00/00/0000  00:00:00',
    type: 'text',
    fieldName: 'provisorio_ate',
  },
  {
    name: 'Data Cadastro:',
    value: '00/00/0000',
    type: 'text',
    fieldName: 'data_cadastro',
  },
  {
    name: 'CNH, CPF ou RG',
    value: 'nomedoarquivo.jpg',
    type: 'file',
    fieldName: 'identificacao_doc',
  },
  {
    name: 'CRECI',
    value: 'nomedoarquivo.jpg',
    type: 'file',
    fieldName: 'creci_doc',
  },
  {
    name: 'Comprovante de residência',
    value: 'nomedoarquivo.jpg',
    type: 'file',
    fieldName: 'comprovante_doc',
  },
];

interface IPerfil {
  [key: string]: string | number;
  dt_nascimento: string;
  dt_cadastro: string;
  dt_provisorio: string;
  id_cidade: number;
  id_regional: number;
  id_tipoUsuario: number;
  id_tipoParceiro: number;
  tx_apelido: string;
  tx_bairro: string;
  tx_cep: string;
  tx_cidade: string;
  tx_creci: string;
  tx_documento: string;
  tx_email: string;
  tx_responsavel: string;
  tx_responsavelCelular: string;
  tx_responsavelEmail: string;
  tx_responsavelTelefone: string;
  tx_logradouro: string;
  tx_nome: string;
  tx_numero: string;
  tx_razaoSocial: string;
  tx_regional: string;
  tx_status: string;
  tx_tipoUsuario: string;
  tx_estado: string;
  id_status: number;
  id_coordenador: number;
  id_zona: number;
}

interface Regional {
  nome: string;
  id: number;
  uf: string;
}

interface PasswordChangeModel {
  tx_CpfCnpj: string;
  senhaAntiga: string;
  senha: string;
  confirmacaoSenha: string;
}

interface Cidade {
  id: number;
  nome: string;
  uf: string;
  // regioes: Zona[];
}
interface Documento {
  id: number;
  id_parceiro: number;
  tx_nomeOriginal: string;
  tx_nomeArquivo: string;
  tx_caminho: string;
  nu_tipoDocumento: number;
  dt_cadastro: Date;
}

interface Message {
  message: string;
  type?: 'error' | 'warning' | 'success';
}

const getDocumentType = (str: string): string => {
  try {
    const val = str.replace(/[^0-9]/gi, '');

    return val.length === 11 ? 'CPF' : 'CNPJ';
  } catch (e) {
    return 'CPF';
  }
};

const MyProfile: React.FC = () => {
  const auth = useAuth();
  const { setLoaderActive } = useLoader();
  const { tab: tabParam, id } : any = useParams();
  const [user, setUser] = useState<User>(
    id ? ({ id, tipo: 0 } as User) : auth.user,
  );

  const [tab, setTab] = useState<string>(tabParam || 'meus-dados');
  const [loading, setLoading] = useState<boolean>(false);
  const [isGerente] = useState<boolean>(auth.user.isGerente);
  const [sucesso, setSucesso] = useState<boolean | undefined>(undefined);
  const [mensagemRetorno, setMensagemRetorno] = useState<string>('');
  const [errors, setErrors] = useState<string[]>([]);
  const [passwordMessages, setPasswordMessages] = useState<Message[]>([]);
  const [files, setFiles] = useState<Documento[]>([]);
  const [values, setValues] = useState<ProfileData[]>(mockData);
  const [perfil, setPerfil] = useState<IPerfil>({} as IPerfil);
  const [gerentesParceria, setGerentesParceria] = useState<ICorretorSigavi[]>([]); //Lista Todos os Gerentes
  const [gerentesParceriaFiltro, setGerentesParceriaFiltro] = useState<ICorretorSigavi[]>([]); //Lista Todos os Gerentes
  const [superintendentesParceria, setSuperintendentesParceria] = useState<ICorretorSigavi[]>([]); //Lista Todos os Superintendentes
  const [listaZonas, setListaZona] = useState<Zona[]>([]); //Lista Todos as Regiões
  //const [gerente, setGerente] = useState<any[]>([]);
  const [gerente, setGerente] = useState<ICorretorSigavi>();
  //const [coordenador, setCoordenador] = useState<any[]>([]);
  const [coordenador, setCoordenador] = useState<ICorretorSigavi>();
  const [superintendente, setSuperintendente] = useState<ICorretorSigavi>();
  const [zona, setZona] =  useState<Zona|null>();
  const [perfilUpdateData, setPerfilUpdateData] = useState<IPerfil>(
    {} as IPerfil,
  );
  const [isOpen, setIsOpen] = useState<any>(false);
  const [isOpenMessage, setIsOpenMessage] = useState<any>(false);
  const [isConfirma, setIsConfirma] = useState<any>(false);

  const [regionais, setRegionais] = useState<Regional[]>([]);
  const [tiposParceiros] = useState<ISelectOption[]>([
    {
      label: 'Autônomo',
      value: 2,
    },
    {
      label: 'Imobiliária',
      value: 1,
    },
  ]);

  const formRefProfile = useRef<FormHandles>(null);
  const formRefPassword = useRef<FormHandles>(null);
  const formRefProfileFiles = useRef<FormHandles>(null);
  const [passwordState, setPasswordState] = useState(false);
  const [passwordValid, setPasswordValid] = useState({
    hasSix: false,
    hasEighteen: false,
    hasUpper: false,
    hasLower: false,
    hasNumber: false,
    hasSpecial: false,
  });

  const checkPassword = (passwordValue: string) => {
    let passwordValidState = { ...passwordValid },
      hasSpecialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
      hasNumbers = /[0-9]/,
      hasLower = /[a-z]/,
      hasUpper = /[A-Z]/,
      testSpecial = hasSpecialChar.test(passwordValue),
      testNumbers = hasNumbers.test(passwordValue),
      testLower = hasLower.test(passwordValue),
      testUpper = hasUpper.test(passwordValue),
      testSixChar = passwordValue.length >= 6 ? true : false,
      testEighteenChar = passwordValue.length <= 18 ? true : false;

    passwordValidState.hasSix = testSixChar;
    passwordValidState.hasEighteen = testEighteenChar;
    passwordValidState.hasUpper = testUpper;
    passwordValidState.hasLower = testLower;
    passwordValidState.hasNumber = testNumbers;
    passwordValidState.hasSpecial = testSpecial;
    setPasswordValid(passwordValidState);
  };

  useEffect(() => {
    async function loadData(): Promise<void> {
      try {
        const { data: regionaisData } = await api.get<Regional[]>(`Regionais`);
        if (user.tipo === 0) {
          const { data: documentos } = await api.get<Documento[]>(
            `DocumentosParceiro/${user.id}`,
          );
          setFiles(documentos);
        }

        setRegionais(regionaisData);
        const defaultRegional = regionaisData.find(
          reg => reg.id === perfil.id_regional,
        );

        let cidadesValues: ISelectOption[] = [];

        if (defaultRegional) {
          const { data: cidadesData } = await api.get<Cidade[]>(`Cidades`, {
            params: {
              uf: defaultRegional.uf,
            },
          });

          cidadesValues = cidadesData.map(cidade => ({
            value: cidade.id,
            label: cidade.nome,
          }));
        }

        const regionaisValues = regionaisData.map(regional => ({
          value: regional.id,
          label: regional.nome,
        }));

        const estados = [
          {
            label: 'Rio de Janeiro',
            value: 19,
          },
          {
            label: 'São Paulo',
            value: 26,
          },
        ];

        const optionsStatus = [
          {
            label: 'Cadastro em andamento',
            value: -2,
          },
          {
            label: 'Provisório',
            value: -1,
          },
          {
            label: 'Pendente RH',
            value: 2,
          },
          {
            label: 'Inativo',
            value: 0,
          },
          {
            label: 'Ativo',
            value: 1,
          },
        ];


        const optionsStatusProvPend = [
          {
            label: 'Provisório',
            value: -1,
          },
          {
            label: 'Pendente RH',
            value: 2,
          },
        ];

        const editing = auth.user.id !== user.id;

        //Status Ativo e Edição = true
        const statusativo = (perfil.id_status === 1) && editing;

        //Gerente (14, 17, 27 e status= Provisório ou Pendente)
        var statusProvisorioPendente = false;
        if ((auth.user.idCargoSigavi === 14 || auth.user.idCargoSigavi === 17 || auth.user.idCargoSigavi === 27) &&
           (perfil.id_status === -1 || perfil.id_status === 2)){
              statusProvisorioPendente = true;
            }


        const newValues: ProfileData[] = [
          // {
          //   name: 'Tipo parceiro',
          //   value: perfil.id_tipoUsuario,
          //   type: 'select',
          //   fieldName: 'id_tipoUsuario',
          //   options: tiposParceiros,
          // },
          {
            name: 'Apelido',
            value: perfil.tx_apelido,
            type: 'text',
            fieldName: 'tx_apelido',
            tipo: null,
          },
          {
            name: 'Nome',
            value: perfil.tx_nome ?? perfil.tx_razaoSocial,
            type: 'text',
            fieldName: 'tx_nome',
            tipo: null,
          },
          {
            name: 'Nome111',
            value: perfil.tx_nome ?? perfil.tx_razaoSocial,
            type: 'text',
            fieldName: 'tx_nome',
            tipo: 2,
            editing,
          },
          {
            name: 'Razão Social',
            value: perfil.tx_razaoSocial,
            type: 'text',
            fieldName: 'tx_razaoSocial',
            tipo: 1,
            editing,
          },
          {
            name: 'E-mail',
            value: perfil.tx_email,
            type: 'email',
            fieldName: 'tx_email',
            tipo: 2,
            editing,
          },
          {
            name: getDocumentType(perfil.tx_documento),
            value: perfil.tx_documento,
            type: 'text',
            fieldName: 'tx_documento',
            mask: '999.999.999-99',
            editing: statusativo? false: editing,
          },
          {
            name: 'Data nascimento',
            value: perfil.dt_nascimento,
            type: 'date',
            fieldName: 'dt_nascimento',
            tipo: 2,
            editing            
          },
          {
            name: 'Situação',
            value: perfil.id_status,
            type: 'select',
            fieldName: 'id_status',
            editing: statusativo? false: editing,
            options: (statusProvisorioPendente && editing)? optionsStatusProvPend: optionsStatus,
            tipo: 0,
          },
          {
            name: 'CRECI',
            value: perfil.tx_creci,
            type: 'text',
            fieldName: 'tx_creci',
            mask: '999999-a',
            editing: statusativo? false: editing,
          },
          {
            name: 'Regional',
            value: perfil.id_regional,
            type: 'select',
            fieldName: 'id_regional',
            options: regionaisValues,
            editing,
          },
          {
            name: 'Responsável',
            value: perfil.tx_responsavel,
            type: 'text',
            fieldName: 'tx_responsavel',
            tipo: 1,
            editing,
          },
          {
            name: 'E-mail do responsável',
            value: perfil.tx_responsavelEmail,
            type: 'email',
            fieldName: 'tx_responsavelEmail',
            tipo: 1,
            editing,
          },
          {
            name: 'Telefone do responsável',
            value: perfil.tx_responsavelTelefone,
            type: 'tel',
            mask: '99999-9999',
            fieldName: 'tx_responsavelTelefone',
            tipo: 1,
            editing,
          },
          {
            name: 'Celular do responsável',
            value: perfil.tx_celular,
            type: 'tel',
            mask: '99999-9999',
            fieldName: 'tx_celular',
            tipo: 1,
            editing,
          },
          // {
          //   name: 'Complemento',
          //   value: perfil.tx_complemento,
          //   type: 'text',
          //   fieldName: 'complemento',
          // },
          {
            name: 'Estado',
            value: perfil.id_estado,
            type: 'select',
            fieldName: 'estado',
            editing,
            options: estados,
            tipo: 0,
          },
          {
            name: 'Cidade',
            value: perfil.id_cidade,
            type: 'select',
            fieldName: 'id_cidade',
            options: cidadesValues,
            editing,
            tipo: 0,
          },
          {
            name: 'Bairro',
            value: perfil.tx_bairro,
            type: 'text',
            fieldName: 'tx_bairro',
            editing,
            tipo: 0,
          },
          {
            name: 'Logradouro',
            value: perfil.tx_logradouro,
            type: 'text',
            fieldName: 'tx_logradouro',
            editing,
            tipo: 0,
          },
          {
            name: 'Número',
            value: perfil.tx_numero,
            type: 'number',
            fieldName: 'tx_numero',
            editing,
            tipo: 0,
          },
          {
            name: 'CEP',
            value: perfil.tx_cep,
            type: 'text',
            fieldName: 'tx_cep',
            mask: '99999-999',
            editing,
            tipo: 0,
          },
          {
            name: 'Celular',
            value: perfil.tx_responsavelCelular,
            type: 'text',
            fieldName: 'tx_responsavelCelular',
            tipo: null,
          },
          // {
          //   name: 'Provisório até:',
          //   value: perfil.dt_provisorio,
          //   type: 'text',
          //   fieldName: 'dt_provisorio',
          // },
          // {
          //   name: 'Data Cadastro:',
          //   value: perfil.dt_cadastro,
          //   type: 'text',
          //   fieldName: 'dt_cadastro',
          // },
        ];
        setValues(newValues);
        const data: any = {};
        
        newValues.forEach(v => {
          if (v.type !== 'select') data[v.fieldName] = v.value;
        });
        
        // data.id_regional = regionaisValues.find(
        //   r => r.value === perfil.id_regional,
        // );
        // data.regionais = regionais.find(r => r.id == perfil.id_regional);

        formRefProfile.current?.setData(data);
        setLoaderActive(false);
      } finally {
      }
    }
    if (perfil.dt_cadastro)
      loadData();


  }, [perfil]);

  const ValidaCampos = (data:ProfileFormData) =>{
    if(!data.tx_documento && perfil.id_status != 1){
      toast('Preencha CPF / CNPJ para salvar', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        type: 'error',
      });
      return false;
    }
    else if(!data.tx_creci && perfil.id_status != 1 ){
      toast('Preencha o CRECI para salvar', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        type: 'error',
      });
      return false;
    }
    else if(!data.id_cidade ){
      toast('Selecione a Cidade para salvar', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        type: 'error',
      });
      return false;
    }
    else if(data.id_cidade.value == 5242 && !zona?.value ){
      toast('Selecione a Região para salvar', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        type: 'error',
      });
      return false;
    }
    else if(!data.tx_cep ){
      toast('O CEP não pode ser vazio', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        type: 'error',
      });
      return false;
    }
    return true;
  }
  const handleSubmitProfile: SubmitHandler<ProfileFormData> = async (
    data,
  ): Promise<void> => {
    var id_gerente = gerente?.value ? gerente?.value : 0;
    var id_superintendente = superintendente?.value ? superintendente?.value : 0;
    var id_coordenador = id_gerente > 0 ? id_gerente : id_superintendente;
    var id_zona = zona?.value;

    if (!ValidaCampos(data)){
      return;
    }

    if (id_coordenador == 0 ){
      setIsOpenMessage(true);
      return;
    }

    if (!isConfirma && coordenador?.value != id_coordenador){
      setIsOpen(true);
      return;
    }

    if (data.dt_nascimento == ""){
      data.dt_nascimento = undefined;
    }

    if (perfil.id_status !== -1 && perfil.id_status !== 2){
      data.tx_documento = perfil.tx_documento;
      data.tx_creci = perfil.tx_creci;
    }

    // Atualizar dados
    const obj = {
      ...data,
      id_estado: data.estado?.value,
      id_cidade: data.id_cidade.value,
      id_regional: data.id_regional.value,
      id_status: perfil.id_status == 1? perfil.id_status : data.id_status.value,
      id_tipoParceiro: perfil.id_tipoParceiro,
      id_coordenador: id_coordenador,
      id_zona: id_zona
    };
    delete obj.estado;
    setSucesso(undefined);

    setLoaderActive(true);

    await api
      .post('Perfil', {
        id_parceiro: Number(user.id),
        ...obj,
      })
      .then(() => {
        setMensagemRetorno('Dados salvos com sucesso!');
        setSucesso(true);
      })
      .catch(err => {
        var mensagemErro  = 'Ocorreu um erro ao tentar salvar, tente novamente.';
        if (err.response) {
          const { erro } = err.response.data;
          if (erro == "Creci já cadastrado."){
             mensagemErro = erro;
          }
        }
        setMensagemRetorno(mensagemErro);
        setSucesso(false);
      })

      /*
      .catch(() => {
        setMensagemRetorno('Ocorreu um erro ao tentar salvar, tente novamente');
        setSucesso(false);
      })
      */
      .finally(() => {
        setLoaderActive(false);
      });

      if (isConfirma){
        history.goBack();
      }
  };

  const handlePasswordChange: SubmitHandler<PasswordChangeModel> = async data => {
    const schema = Yup.object().shape({
      senhaAntiga: Yup.string().required('Senha antiga é obrigatória'),
      senha: Yup.string().required('Senha é obrigatória'),
      confirmacaoSenha: Yup.string()
        .oneOf(
          [Yup.ref('senha'), undefined],
          'A senha e a confirmação de senha não são iguais',
        )
        .required('Confirmação é obrigatória'),
    });

    try {
      setLoading(true);

      await schema.validate(data, {
        abortEarly: false,
      });

      // eslint-disable-next-line no-param-reassign
      data.tx_CpfCnpj = perfil.tx_documento;

      const response = await api.post<PasswordChangeModel>(
        'Cadastro/alterar-senha/',
        data,
      );

      const messages: Message[] = [];
      messages.push({
        message: 'Senha alterada com sucesso!',
        type: 'success',
      });

      setPasswordMessages(messages);
    } catch (err: any) {
      const validationErrors: Message[] = [];

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors.push({
            message: error.message,
            type: 'error',
          });
        });
      }

      if (err.response) {
        const { erro } = err.response.data;
        validationErrors.push({
          message: erro,
          type: 'error',
        });
      }

      setPasswordMessages(validationErrors);
    } finally {
      setLoading(false);
    }
  };

  const salvarSenha = useCallback(() => {
    if (formRefPassword.current) {
      formRefPassword.current.submitForm();
    }
  }, [formRefPassword]);

  const handleSubmitProfileFiles: SubmitHandler<ProfileFilesFormData> = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data,
  ): void => {
    // Atualizar dados
  };


  useEffect(() => {
    setLoaderActive(true);

    var id_coordenador = 0;
    let dataSup;
    async function loadGerentes() {
      const resGerentes = await api.get(`Gerente/listGerentesParcerias`);
      var dataGer =
        resGerentes.data.map((d: any) => ({
            label: d.tx_apelido,
            value: d.id_corretorSigavi,
            tx_equipe: d.tx_equipe,
            tx_cargoSigavi: d.tx_cargoSigavi,
          }));
        setGerentesParceria(dataGer);
        var ger = dataGer.filter(e => e.value === id_coordenador);
        if (ger.length > 0){
          setGerente(ger[0]);
          setCoordenador(ger[0]);
          setSuperintendente(dataSup.filter(e => e.label === ger[0].tx_equipe)[0]);
          setGerentesParceriaFiltro(dataGer.filter(e => e.tx_equipe === ger[0].tx_equipe));//Filtra os Gerentes da Equipe
        }
        else{
          var sup = dataSup.filter(e => e.value === id_coordenador);
          if (sup.length > 0){
            setCoordenador(sup[0]);
            setSuperintendente(sup[0]);
            setGerentesParceriaFiltro(dataGer.filter(e => e.tx_equipe === sup[0].label));
          }
          else{
            setGerentesParceriaFiltro(dataGer);
          }
        }
    }

    async function loadSuperintendente() {
      const res = await api.get(`Gerente/GetAllSuperintendente`);
      dataSup =
        res.data.map((d: any) => ({
          label: d.tx_apelido,
          value: d.id_corretorSigavi,
          empresaSigavi: d.tx_empresaSigavi,
          tx_equipe: d.tx_equipe,
          tx_cargoSigavi: d.tx_cargoSigavi
        }));
      setSuperintendentesParceria([{label:"Todos",value:0}, ...dataSup]);
      loadGerentes();
    }

    async function loadZona(id_zona) {
      const { data: zonaData } = await api.get(`Cidades/regioes/:id_cidade`, {
        params: { id_cidade: 5242 }});

      const zonaValues = zonaData.map(z => ({
        value: z.id,
        label: z.nome,
      }));

      setListaZona(zonaValues);
      setZona(zonaValues.filter(e => e.value === id_zona)[0]);
    }

    const itsMe = auth.user.id === user.id;
    const url = `Perfil${itsMe ? '' : `/byId?id=${user.id}&tipo=0`}`;
    api
      .get(url)
      .then(res => {
        setPerfil(res.data);
        setPerfilUpdateData(res.data);
        if (tab === "meus-dados"){
          id_coordenador = res.data.id_coordenador;
          if (res.data.id_cidade == 5242){
            loadZona(res.data.id_zona);
          }
          loadSuperintendente();
        }
      })
      .catch(() => {
        // setLoaderActive(false);
      });
  }, []);

  const salvarAlteracoes = useCallback(() => {
    if (formRefProfile.current) {
      formRefProfile.current.submitForm();
    }
  }, [formRefProfile]);

  const handleAvatarChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const data = new FormData();

      data.append('avatar', e.target.files[0]);

      // api.patch('users/avatar', data).then(res => {
      //   updateUser(res.data);
      // });
    }
  }, []);

  const history = useHistory();

  const onChangeGerente = useCallback(
    (e) => {
      setGerente(e);
      if ((e.value != 0) && (superintendente?.value == 0)){
        var resSuper = superintendentesParceria.filter(f=> f.label == e.tx_equipe)[0];
        if (resSuper){
          setSuperintendente(resSuper);
          setGerentesParceriaFiltro(gerentesParceria.filter(z=> z.tx_equipe == e.tx_equipe));
        }
        else{
          setSuperintendente(superintendentesParceria.filter(f=> f.value === 0)[0]);
        }
      }
    },
    [superintendente, gerente, gerentesParceriaFiltro],
  );

  const onChangeSuperintendente = useCallback(
    (e) => {
      setSuperintendente(e);
      setGerente({label:"[Sem Gerente]",value:0, empresaSigavi:0, tx_equipe:"", tx_cargoSigavi:""});
      if (e.value == 0){
        setGerentesParceriaFiltro(gerentesParceria);
      }
      else{
        setGerentesParceriaFiltro(gerentesParceria.filter(z=> z.tx_equipe == e.label));
      }
    },
    [gerentesParceriaFiltro, superintendente],
  );




  useEffect(
    () => {
      if (isConfirma){
        setIsOpen(false);
        salvarAlteracoes();
      }
    },
    [isConfirma],
  );

  const onChangeCidade = useCallback(
    async ({ value }) => {
      if (value == 5242){
        const { data:zonaData } = await api.get(`Cidades/regioes/:id_cidade`, {
          params: { id_cidade: 5242 }});

        const zonaValues = zonaData.map(z => ({
          value: z.id,
          label: z.nome,
        }));

        setListaZona(zonaValues);
        setZona(zonaValues.filter(e => e.value === value));
      }
      else{
        setListaZona([]);
        setZona(null);
      }
    },
    [listaZonas, zona],
  );

  const onChangeZona = useCallback(
    (e) => {
      setZona(e);
    },
    [zona],
  );

  return (
    <Container>
      <Modal
        isOpen={isOpenMessage}
        style={customStyles}
        contentLabel="Transferência de Corretor"
      >
        <CloseButton onClick={() => setIsOpenMessage(false)}>
          <CloseIcon />
        </CloseButton>
        <ImprimirMsg>
          <h3>Atenção!!!</h3>
          <p>
            Não é permitido salvar o Corretor sem um Gerente ou sem um Superintendente.
          </p>
        </ImprimirMsg>

        <div className="modal-footer">
          <ListaAction>
            <TegraButton isAction
              onClick={() => {setIsOpenMessage(false); }}
            >
              Voltar
            </TegraButton>
          </ListaAction>
        </div>
      </Modal>
      <Modal
        isOpen={isOpen}
        style={customStyles}
        contentLabel="Transferência de Corretor"
      >
        <CloseButton onClick={() => setIsOpen(false)}>
          <CloseIcon />
        </CloseButton>
        <ImprimirMsg>
          <h3>Atenção!!!</h3>
          <p>
            A transferência de corretor é um processo irreversível! Tem certeza que deseja continuar?
          </p>
        </ImprimirMsg>

        <div className="modal-footer">
          <ListaAction>
          <TegraButton isAction
            onClick={() => {setIsConfirma(true); }}
          >
            Continuar
          </TegraButton>
            <TegraButton isAction
              onClick={() => setIsOpen(false)}
            >
                Cancelar
            </TegraButton>
          </ListaAction>
        </div>
      </Modal>
      <TegraOpaqueHero
        title={tab === 'meus-documentos' ? 'Meus documentos' : 'Meus dados'}
        description="Mantenha suas informações sempre atualizadas nesta página do Portal."
      />
      <Content>
        {isGerente && auth.user.id !== user.id && (
          <ProfileTabs>
            <span
              onClick={() => {
                setTab('meus-dados');
                window.location.reload();
              }}
              className={tab === 'meus-dados' ? 'selected' : ''}
            >
              Dados
            </span>
            <span
              onClick={() => setTab('meus-documentos')}
              className={tab === 'meus-documentos' ? 'selected' : ''}
            >
              Documentos
            </span>
          </ProfileTabs>
        )}
        {errors.length >= 1 && (
          <ErrorsContainer>
            {errors.map(error => (
              <p>{error}</p>
            ))}
          </ErrorsContainer>
        )}
        {tab === 'meus-dados' && (
          <ProfileBox>
            <Form ref={formRefProfile} onSubmit={handleSubmitProfile}>
              {user.tipo !== 0 && (
                <ProfileAvatar htmlFor="avatar" canUpdate={0}>
                  <img src={user.foto} alt={user.nome} />
                </ProfileAvatar>
              )}
              <DadosList>
                {values
                  .filter(
                    dado =>
                      dado.type !== 'file' &&
                      (dado.tipo === undefined ||
                        (dado.tipo === 1 && perfil?.id_tipoParceiro === 1) ||
                        (dado.tipo === 2 && (perfil?.id_tipoParceiro === 2 || perfil?.id_tipoParceiro === 21)) ||
                        (dado.tipo === 0 && perfil?.id_tipoParceiro)),
                  )
                  .map(dado => (
                    <DadosItem key={dado.name.trim()}>
                      <DadosItemContent>
                        {dado.type !== 'date' &&
                          <p style={{marginBottom:'11px'}}>{dado.name}</p>
                        }                        
                        <div>
                          {(!dado.editing || ((perfil.id_tipoParceiro == 1 && auth.user.idCargoSigavi == 14))) && (
                            <strong>
                              {dado.type !== 'select' && dado.value}
                              {dado.type === 'select' &&
                                dado.options &&
                                dado.options.find(
                                  option => option.value === dado.value,
                                )?.label}
                            </strong>
                          )}

                          {perfil.id_tipoParceiro != 1 && dado.editing && dado.type === 'date' &&
                            (
                              <Input
                                label={dado.name}
                                name={dado.fieldName}
                                type='date'
                                value={dado.value ? dado.value.substring(0,10) : null}
                              /> 
                            )
                          }

                          {(dado.editing && 
                            ((perfil.id_tipoParceiro != 1 && auth.user.idCargoSigavi != 14) || 
                             (perfil.id_tipoParceiro != 1) || 
                             (perfil.id_tipoParceiro == 1 && auth.user.idCargoSigavi != 14))) && 
                            (dado.type === 'text' ||
                            dado.type === 'email' ||
                            dado.type === 'tel' ||
                            dado.type === 'number' 
                            ? 
                            (
                              <TegraInputWithMask
                                name={dado.fieldName}
                                label=""
                                formRef={formRefProfile}
                                type={dado.type}
                                mask={dado.mask || ''}
                                defaultValue={dado.value}
                              />
                            ) 
                            : dado.type === 'select' &&
                            (
                              <TegraSelect
                                name={dado.fieldName}
                                label=""
                                formRef={formRefProfile}
                                defaultValue={
                                  dado.options &&
                                  dado.options.find(
                                    option => option.value === dado.value,
                                  )
                                }
                                options={dado.options}
                                onChange={dado.name == 'Cidade'?  onChangeCidade : e =>null}
                              />
                            ))}
                        </div>
                      </DadosItemContent>
                    </DadosItem>
                  ))}
                  {(!window.location.href.includes('perfil/meus-dados') && 
                                              ((perfil.id_tipoParceiro != 1 && auth.user.idCargoSigavi != 14) || 
                                              (perfil.id_tipoParceiro != 1) || 
                                              (perfil.id_tipoParceiro == 1 && auth.user.idCargoSigavi != 14))) && 
                 
                    (<DadosItem>
                        <DadosItemContent>
                            <TegraSelect
                                placeholder="Todos"
                                noOptionsMessage={() => 'Nenhum selecionado'}
                                label="Região"
                                name="zona"
                                options={listaZonas}
                                // erro de compilacao
                                // value={zona}
                                onChange={e=> onChangeZona(e)}
                                isDisabled={listaZonas.length == 0}
                            />
                        </DadosItemContent>
                    </DadosItem>)
                  }
                  {isGerente && id && 
                                             ((perfil.id_tipoParceiro != 1 && auth.user.idCargoSigavi != 14) || 
                                              (perfil.id_tipoParceiro != 1) || 
                                              (perfil.id_tipoParceiro == 1 && auth.user.idCargoSigavi != 14)) && 
                 
                  (
                    <DadosItem>
                        <DadosItemContent>
                          <ContainerSuperintendente>
                            <div className="selectSuperintendente">
                              <TegraSelect
                                placeholder="Todos"
                                noOptionsMessage={() => 'Nenhum selecionado'}
                                label="Superintendente"
                                name="superintendente"
                                options={superintendentesParceria}
                                // erro de compilacao
                                // value={superintendente}
                                onChange={(e) => onChangeSuperintendente(e)}
                              />
                            </div>
                            <div className="selectSuperintendente">
                              <TegraSelect
                                placeholder="[Sem Gerente]"
                                noOptionsMessage={() => 'Nenhum selecionado'}
                                label="Gerente"
                                name="gerente"
                                options={[{label:"[Sem Gerente]",value:0}, ...gerentesParceriaFiltro]}
                                // erro de compilacao
                                // value={gerente}
                                onChange={(e) => onChangeGerente(e)}
                              />
                            </div>
                          </ContainerSuperintendente>
                        </DadosItemContent>
                    </DadosItem>
                  )}
              </DadosList>

              {mensagemRetorno && sucesso && (
                <>
                  <MensagemSucesso>{mensagemRetorno}</MensagemSucesso>
                </>
              )}
              {mensagemRetorno && !sucesso && (
                <>
                  <MensagemErro>{mensagemRetorno}</MensagemErro>
                </>
              )}

              {isGerente && id && 
                                         ((perfil.id_tipoParceiro != 1 && auth.user.idCargoSigavi != 14) || 
                                          (perfil.id_tipoParceiro != 1) || 
                                          (perfil.id_tipoParceiro == 1 && auth.user.idCargoSigavi != 14)) && 
             
              (
                <ProfileBoxActions>
                  <TegraButton isAction onClick={() => salvarAlteracoes()}>
                    Salvar
                  </TegraButton>
                  <TegraButton isAction onClick={() => history.goBack()}>
                    Voltar
                  </TegraButton>
                </ProfileBoxActions>
              )}
            </Form>
            { (user.idCargoSigavi == 0 || (perfil.id_tipoParceiro == 1 || perfil.id_tipoParceiro == 2)) && 
                                       ((perfil.id_tipoParceiro != 1 && auth.user.idCargoSigavi != 14) || 
                                        (perfil.id_tipoParceiro != 1) || 
                                        (perfil.id_tipoParceiro == 1 && auth.user.idCargoSigavi != 14)) &&            
            ( //ajustar aqui
              <Container className="formContainer">
              <Form ref={formRefPassword} onSubmit={handlePasswordChange}>
                <div className="title">
                  <div className="withSubtitle">
                    <BoxTitle>Troca de senha</BoxTitle>
                  </div>
                </div>
                {passwordMessages.length >= 1 && (
                  <ErrorsContainer>
                    {passwordMessages.map(({ message, type }) => (
                      <p className={type}>{message}</p>
                    ))}
                  </ErrorsContainer>
                )}

                <div className="password-orientations">
                  <p className="orientations-header">
                    A senha deve seguir o seguinte padrão:
                  </p>
                  <p
                    className={passwordValid.hasSix ? 'orientation-valid' : ''}
                  >
                    Mínimo de 6 caracteres;
                  </p>
                  <p
                    className={
                      passwordValid.hasEighteen ? 'orientation-valid' : ''
                    }
                  >
                    Máximo de 18 caracteres;
                  </p>
                  <p
                    className={
                      passwordValid.hasUpper ? 'orientation-valid' : ''
                    }
                  >
                    Possuir letras maiusculas;
                  </p>
                  <p
                    className={
                      passwordValid.hasLower ? 'orientation-valid' : ''
                    }
                  >
                    Possuir letras minusculas;
                  </p>
                  <p
                    className={
                      passwordValid.hasNumber ? 'orientation-valid' : ''
                    }
                  >
                    Possuir ao menos um número;
                  </p>
                  <p
                    className={
                      passwordValid.hasSpecial ? 'orientation-valid' : ''
                    }
                  >
                    Possuir ao menos um caracter especial;
                  </p>
                </div>
                <InputWrapper>
                  <TegraInput
                    label="Senha atual"
                    type={passwordState ? 'text' : 'password'}
                    minLength={6}
                    maxLength={18}
                    name="senhaAntiga"
                  />
                  {passwordState ? (
                    <FaEyeSlash
                      onClick={() => setPasswordState(false)}
                      className="icon"
                    />
                  ) : (
                    <FaEye
                      onClick={() => setPasswordState(true)}
                      className="icon"
                    />
                  )}
                </InputWrapper>
                <InputWrapper>
                  <TegraInput
                    label="Nova Senha"
                    type={passwordState ? 'text' : 'password'}
                    minLength={6}
                    maxLength={18}
                    name="senha"
                    onChange={e => checkPassword(e.target.value)}
                  />
                  {passwordState ? (
                    <FaEyeSlash
                      onClick={() => setPasswordState(false)}
                      className="icon"
                    />
                  ) : (
                    <FaEye
                      onClick={() => setPasswordState(true)}
                      className="icon"
                    />
                  )}
                </InputWrapper>
                <InputWrapper>
                  <TegraInput
                    label="Confirmação de nova senha"
                    type={passwordState ? 'text' : 'password'}
                    minLength={6}
                    maxLength={18}
                    name="confirmacaoSenha"
                  />
                  {passwordState ? (
                    <FaEyeSlash
                      onClick={() => setPasswordState(false)}
                      className="icon"
                    />
                  ) : (
                    <FaEye
                      onClick={() => setPasswordState(true)}
                      className="icon"
                    />
                  )}
                </InputWrapper>

                <TegraButton
                  type="button"
                  isAction
                  isDisabled={loading}
                  onClick={() => salvarSenha()}
                >
                  Salvar senha
                </TegraButton>
              </Form>
            </Container>
            )}
          </ProfileBox>
        )}
        {tab === 'meus-documentos' && (
          <ProfileFilesBox>
            <Form ref={formRefProfileFiles} onSubmit={handleSubmitProfileFiles}>
              <GridContainer
                style={{
                  padding: 0,
                  marginLeft: '0px',
                  marginRight: '0px',
                  maxWidth: '100%',
                }}
              >
                {perfil?.id_tipoParceiro == 1 && (
                  <RowDoc>
                    <Col sm={12}>
                      <TegraDocumentInput
                        parceiro={user.id}
                        canEdit={perfil.id_status === -1}
                        sendButton={true}
                        setErrors={setErrors}
                        formRef={formRefProfileFiles}
                        label="Contrato Social e Cartão CNPJ Atualizado"
                        name="contrato_social"
                        enablePreview={perfil.id_tipoParceiro == 1 && auth.user.idCargoSigavi == 14}
                        filename={
                          files.find(f => f.nu_tipoDocumento === 3)
                            ?.tx_nomeOriginal
                        }                        
                      />
                      <div style={{marginTop:'10px'}}></div>
                      <TegraDocumentInput
                        parceiro={user.id}
                        canEdit={perfil.id_status === -1}
                        sendButton={true}
                        setErrors={setErrors}
                        formRef={formRefProfileFiles}
                        label=""
                        name="cartao_cnpj"
                        enablePreview={perfil.id_tipoParceiro == 1 && auth.user.idCargoSigavi == 14}
                        filename={
                          files.find(f => f.nu_tipoDocumento === 4)
                            ?.tx_nomeOriginal
                        }
                      />
                    </Col>
                  </RowDoc>
                )}
                <RowDoc>
                  <Col sm={12}>
                    <TegraDocumentInput
                      parceiro={user.id}
                      canEdit={perfil.id_status === -1}
                      sendButton={true}
                      setErrors={setErrors}
                      formRef={formRefProfileFiles}
                      label={perfil?.id_tipoParceiro == 1 ? "CNH, CPF ou RG dos sócios" : "CNH, CPF ou RG"}
                      name="identificacao_doc"
                      enablePreview={perfil.id_tipoParceiro == 1 && auth.user.idCargoSigavi == 14}
                      filename={
                        files.find(f => f.nu_tipoDocumento === 0)
                          ?.tx_nomeOriginal
                      }
                    />
                  </Col>
                </RowDoc>
                <RowDoc>
                  <Col sm={12}>
                    <TegraDocumentInput
                      parceiro={user.id}
                      canEdit={perfil.id_status === -1}
                      sendButton={true}
                      setErrors={setErrors}
                      formRef={formRefProfileFiles}
                      label={perfil?.id_tipoParceiro == 1 ? "Certidão do CRECI Atualizada" : "CRECI"}
                      name="creci_doc"
                      enablePreview={perfil.id_tipoParceiro == 1 && auth.user.idCargoSigavi == 14}
                      filename={
                        files.find(f => f.nu_tipoDocumento === 1)
                          ?.tx_nomeOriginal
                      }                      
                    />
                  </Col>
                </RowDoc>
                <RowDoc>
                  <Col sm={12}>
                    <TegraDocumentInput
                      parceiro={user.id}
                      canEdit={perfil.id_status === -1}
                      sendButton={true}
                      setErrors={setErrors}
                      formRef={formRefProfileFiles}
                      label={perfil?.id_tipoParceiro == 1 ? "Comprovante de endereço atualizado com máximo de 03 meses" : "Comprovante de residência"}
                      name="comp_residencia_doc"
                      enablePreview={perfil.id_tipoParceiro == 1 && auth.user.idCargoSigavi == 14}
                      filename={
                        files.find(f => f.nu_tipoDocumento === 2)
                          ?.tx_nomeOriginal
                      }
                    />
                  </Col>
                </RowDoc>
              </GridContainer>
            </Form>
          </ProfileFilesBox>
        )}
      </Content>
    </Container>
  );
};

export default MyProfile;
