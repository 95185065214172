/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/ban-types */
import React, { RefObject, useState, useCallback, useEffect } from 'react';

import { FiChevronLeft } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import TegraButton from '../../../../components/TegraButton';
import TegraDivider from '../../../../components/TegraDivider';
import {
  Wrapper,
  CustomSelect,
  WizardNavigationContainer,
  WizardNavigationGoBack,
  Subtitle,
  ExtraActions,
  GoBackContainer,
  Title,
  ErrorsContainer,
  LineDivTitleErrors,
} from './styles';
import TegraRadio from '../../../../components/TegraRadio';
import api from '../../../../services/api';
import ajaxLoading from '../../../../assets/ajax-loader.gif';
import { CorretorFormData } from '..';
// import { StepProps, withWizard } from 'react-albus';

interface StepProps {
  updateForm: (data: CorretorFormData) => void;
  id: string;
  formRef: RefObject<FormHandles>;
  formData: CorretorFormData;
  changeStep: (step: string) => void;
}

interface Gerente {
  id_corretorSigavi: number;
  tx_apelido: string;
  tx_nome: string;
}

const StepTwo: React.FC<StepProps> = ({
  formRef,
  updateForm,
  formData,
  changeStep,
}) => {
  const [defaultValues, setDefaultValues] = useState<CorretorFormData>(() => {
    const data = { ...formData };    
    return data;
  });

  const [loading, setLoading] = useState<boolean>(true);

  const [errors, setErrors] = useState<string[]>([]);

  const [descErrors, setDescErrors] = useState<string>('');

  const [gerentes, setGerentes] = useState<Gerente[]>([]);

  const getSelectDefaultValue = useCallback(
    value => {
      const findSelectValue = gerentes.find(
        item => item.id_corretorSigavi === value,
      );
      if (!findSelectValue) {
        return { value: '', label: '' };
      }
      return {
        label: findSelectValue.tx_nome,
        value: findSelectValue.id_corretorSigavi,
      };
    },
    [gerentes],
  );

  useEffect(() => {
    const { regional } = defaultValues;
    api
      .get<Gerente[]>('Cadastro/gerentes/:id_regional', {
        params: {
          id_regional: Number(regional.value),
        },
      })
      .then(res => {
        setLoading(false);
        const { data } = res;
        
        setGerentes(data);
      });
  }, [defaultValues]);

  const handleStepSubmit = useCallback(async () => {
    let data: CorretorFormData = {} as CorretorFormData;

    if (!formRef.current) {
      return;
    }

    setDescErrors('');
    setErrors([]);

    try {
      data = defaultValues as CorretorFormData;
      updateForm({ ...defaultValues });

      const schema = Yup.object().shape({
        possui_gerente: Yup.boolean(),
        ...(data.possui_gerente
          ? {
              gerente: Yup.object()
                .nullable()
                .required('Gerente')
                .shape({
                  value: Yup.string().required('Selecione o gerente'),
                }),
            }
          : {}),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      setLoading(true);
      const requestData = {
        // só utilizado caso esteja editando
        id_parceiro: formData?.id_parceiro,
        id_tipoParceiro: 2,
        tx_cpf: data.cpf,
        tx_nome: data.nome,
        tx_email: data.email,
        tx_telefone: data.telefone,
        id_zona: data.zona?.value,
        tx_zona: data.zona?.label,
        tx_celular: data.celular,
        tx_logradouro: data.endereco,
        tx_numero: data.numero,
        tx_complemento: data.complemento,
        tx_bairro: data.bairro,
        tx_cep: data.cep,
        id_regional: data.regional?.value,
        tx_regional: data.regional?.label,
        id_cidade: data.cidade?.value,
        tx_cidade: data.cidade?.label,
        tx_creci: data.creci,
        dt_cadastro: new Date().toISOString(),
        tx_uf: data.regional.uf,        
        id_gerente: data.gerente.value || 0,
        tx_gerente: data.gerente.label,
        hasGerente: data.possui_gerente,
        dt_nascimento: data.dt_nascimento,
        tx_origemContato: data.contato?.label,
        tx_outraOrigem: data.contato?.label === "Outros" ? data.contato?.outraOrigem : null,
      };
      api
        .post('Cadastro/part2', requestData)
        .then(res => {
          setLoading(false);
          const { sucesso } = res.data;
          if (sucesso) {
            data.step = 'stepThree';
            sessionStorage.setItem('_cadastro', JSON.stringify(data));
            changeStep('stepThree');
          }
        })
        .catch(err => {
          setLoading(false);
          if (err.response) {
            const { erro } = err.response.data;

            const validationErrors: string[] = [];

            validationErrors.push(erro);

            setErrors(validationErrors);
          }
        });
    } catch (err) {
      const validationErrors: string[] = [];

      if (err instanceof Yup.ValidationError) {
        setDescErrors('Um ou mais campos preenchidos consta alguma informação inválida. Por favor, verifique os campos e tente novamente.');
        err.inner.forEach(error => {
          validationErrors.push(error.message);
        });
        setErrors(validationErrors);
      }
    }
  }, [formRef, defaultValues, updateForm, formData, changeStep]);

  const handlePrevious = useCallback(() => {
    const clearObject = {
      possui_gerente: false,
      gerente: {
        label: '',
        value: '',
      },
    };

    updateForm({ ...defaultValues, ...clearObject });

    changeStep('stepOne');
  }, [changeStep, defaultValues, updateForm]);

  const handleSelectChange = useCallback(
    data => {
      updateForm({ ...defaultValues, gerente: data });

      setDefaultValues({ ...defaultValues, gerente: data });
    },
    [defaultValues, updateForm],
  );

  const handleRadioChange = useCallback(
    event => {
      const possui_gerente = event.target.value === '1';
      let { gerente } = defaultValues;

      if (!gerente || !possui_gerente) {
        gerente = { label: '', value: '' };
      }
      if (formRef.current) {
        updateForm({ ...defaultValues, possui_gerente, gerente });
      }
      setDefaultValues({ ...defaultValues, possui_gerente, gerente });
    },
    [defaultValues, formRef, updateForm],
  );

  return (
    <Wrapper>
      <ExtraActions>
        <GoBackContainer>
          <FiChevronLeft size={10} />
          <span onClick={handlePrevious}>Voltar</span>
        </GoBackContainer>
        <Link to="/" className="jaPossuoCadastro">
          Já possuo cadastro
        </Link>
      </ExtraActions>
      <Title>Cadastre-se</Title>
      <Subtitle>Sou um corretor</Subtitle>
      {errors.length >= 1 && (
        <ErrorsContainer>
          {descErrors !== '' &&(
           <div>
              <p className="info-error" >{descErrors} </p>   
              <LineDivTitleErrors/>    
            </div>
          )}          
          {errors.map(error => (

              <p className="info-error" dangerouslySetInnerHTML={{ __html: error }} />              

          ))}
        </ErrorsContainer>
      )}
      <TegraDivider axis="x" color="#e6e6e6" />
      <p>Possui Gerente?</p>
      <div className="radio-group">
        <TegraRadio
          style={{ marginTop: '20px', marginBottom: '20px' }}
          defaultValue={1}
          name="possui_gerente"
          label="Sim"
          checked={defaultValues.possui_gerente === true}
          onChange={handleRadioChange}
        />

        {defaultValues.possui_gerente && gerentes.length > 0 && (
          <CustomSelect
            onChange={handleSelectChange}
            label="Selecione o gerente"
            placeholder=""
            defaultValue={getSelectDefaultValue(defaultValues.gerente.value)}
            noOptionsMessage={() => <span>Nenhum gerente selecionado</span>}
            name="gerente"
            options={gerentes.map(gerente => ({
              value: gerente.id_corretorSigavi,
              label: `${gerente.tx_apelido} (${gerente.tx_nome})`,
            }))}
          />
        )}

        <TegraRadio
          style={{ marginTop: '20px', marginBottom: '20px' }}
          defaultValue={0}
          name="possui_gerente"
          label="Não"
          checked={defaultValues.possui_gerente === false}
          onChange={handleRadioChange}
        />
      </div>
      <WizardNavigationContainer>
        <WizardNavigationGoBack>
          <FiChevronLeft size={10} />
          <span onClick={handlePrevious}>Voltar</span>
        </WizardNavigationGoBack>
        <TegraButton isAction onClick={handleStepSubmit} isDisabled={loading} style={{margin:'0px 0px'}}>
          Próximo
          {loading && <img src={ajaxLoading} alt="Carregando" />}
        </TegraButton>
      </WizardNavigationContainer>
    </Wrapper>
  );
};

export default StepTwo;
