/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/ban-types */
import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
  RefObject,
} from 'react';
import { useField, FormHandles } from '@unform/core';
import api, { baseURL } from '../../services/api';

import { Container } from './styles';
import uploadIcon from '../../assets/tegra-upload-icon.svg';
import uploadCancelIcon from '../../assets/tegra-upload-cancel-icon.svg';
import ajaxLoading from '../../assets/ajax-loader-white.gif';

interface Props {
  name: string;
  parceiro?: number;
  setErrors?: Function;
  formRef: RefObject<FormHandles>;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const TegraFileInput: React.FC<InputProps> = ({
  name,
  parceiro,
  formRef,
  setErrors,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [tipoDocumento] = useState(() => {
    let t = 0;
    switch (fieldName) {
      case 'identificacao_doc':
        t = 0;
        break;
      case 'creci_doc':
        t = 1;
        break;
      case 'comp_residencia_doc':
        t = 2;
        break;
      case 'contrato_social':
        t = 3;
        break;
      case 'cartao_cnpj':
        t = 4;
        break;          
      default:
        t = 0;
        break;
    }
    return t;
  });
  const [preview, setPreview] = useState(() => {
    const cadastro = JSON.parse(sessionStorage.getItem('_cadastro') || '');
    let fileObj;
    if (cadastro.files) {
      fileObj = cadastro.files.find((f: { tipoDocumento: number }) => {
        return f.tipoDocumento === tipoDocumento;
      });
    }
    return fileObj?.arquivo || '';
  });
  const [loading, setLoading] = useState(false);

  const handlePreview = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];

      if (!file) {
        setPreview('');
        return;
      }

      const previewName = file.name;

      if (!formRef.current) {
        throw new Error('Sem form');
      }

      setErrors && setErrors([]);
      setLoading(true);
      const multipart = new FormData();
      multipart.append('documento', file);
      api
        .post(
          `DocumentosParceiro/${parceiro}?tipoDocumento=${tipoDocumento}`,
          multipart,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          },
        )
        .then(res => {
          setLoading(false);
          setPreview(previewName);
          if (sessionStorage.getItem('_cadastro')) {
            try {
              const cadastro = JSON.parse(
                sessionStorage.getItem('_cadastro') || '',
              );
              let fileObj;
              if (cadastro.files) {
                fileObj = cadastro.files.find(
                  (f: { tipoDocumento: number }) => {
                    return f.tipoDocumento === tipoDocumento;
                  },
                );
              } else cadastro.files = [];
              if (!fileObj) {
                fileObj = { tipoDocumento };
                cadastro.files.push(fileObj);
              }
              fileObj.arquivo = previewName;
              sessionStorage.setItem('_cadastro', JSON.stringify(cadastro));
            } catch (e) {
              console.error(e);
              setErrors && setErrors(['Arquivo inválido']);
            }
          }
        })
        .catch(err => {
          setLoading(false);
          if (err.response) {
            const { erro } = err.response.data;

            const validationErrors: string[] = [];

            validationErrors.push(erro);

            setErrors && setErrors(validationErrors);
          } else {
            setErrors && setErrors(['Arquivo inválido']);
          }
        });
    },
    [formRef],
  );

  const handleCancel = useCallback(() => {
    if (!inputRef.current) {
      throw new Error('No ref');
    }

    const cadastro = JSON.parse(
      sessionStorage.getItem('_cadastro') || '',
    );

    
    if (cadastro.files){
      var pos = cadastro.files.map(
        function(e) { 
          return e.tipoDocumento; 
        }).indexOf(tipoDocumento);
      
      cadastro.files.splice(pos, 1);      
      console.log(cadastro.files);
      sessionStorage.setItem('_cadastro', JSON.stringify(cadastro));
    }
    
    inputRef.current.value = '';
    setPreview('');
  }, [inputRef]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        ref.value = '';
        setPreview('');
      },
      setValue(_: HTMLInputElement, value: string) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container inputHasValue={!!preview}>
      {preview && <p>{preview}</p>}
      <div className="upload-btn-wrapper">
        {!preview && !loading && (
          <>
            <button type="button" className="uploadBtn">
              <span>Enviar arquivo</span>
              <img src={uploadIcon} alt="Upload" />
            </button>
          </>
        )}
        {loading && <img src={ajaxLoading} alt="Carregando" />}
        <input type="file" ref={inputRef} onChange={handlePreview} {...rest} />
      </div>
      {preview && (
        <button type="button" className="cancelBtn" onClick={handleCancel}>
          <img src={uploadCancelIcon} alt="Upload" />
        </button>
      )}
    </Container>
  );
};

export default TegraFileInput;
