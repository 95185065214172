/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-return-assign */
import ReactDOM from "react-dom";
import React, { useState, useCallback, RefObject, useEffect, useRef } from 'react';

import api from '../../services/api';

import TegraSelectDropDown from '../../components/TegraSelectDropDown';

import searchIcon from '../../assets/tegra-search-product.svg';

import {
    Container,
    ContainerMenu,
    ContainerButton,
    ButtonDrop,
    DropDownPreco,
    SeparadorOption,
    SeparadorOptionBusca,
    SeparadorOptionPreco,
    SeparadorOptionSearch,
    SeparadorOptionBusca2,
    SeparadorOptionPreco2,    
    ButtonSerch,
    InputFiltro,
    FilterActionsContainer
  } from './styles';
  
import TegraButton from '../TegraButton';

export interface IFiltroItemOption {
    label: string;
    value: string;
}

interface IEmpreendimentoAndamento {
    nome: string;
    id: string;
}
    
interface IEmpreendimentoRegiao {
    nome: string;
    id: string;
}

interface TegraFiltroProps {
    doSearch: Function;
    idEstado: number;
    estadoFiltro?: boolean;
 }

const TegraFiltroHorinzontal: React.FC<TegraFiltroProps> = ({
    doSearch,
    idEstado,
    estadoFiltro = true
}) => {
    const [andamento, setAndamento] = useState<IFiltroItemOption[]>([]);
    const [andamentoModal, setAndamentoModal] = useState<IFiltroItemOption[]>([]);
    const [regiao, setRegiao] = useState<IFiltroItemOption[]>([]);
    const [regiaoModal, setRegiaoModal] = useState<IFiltroItemOption[]>([]);
    const [dormitorio, setDormitorio] = useState<IFiltroItemOption[]>([]);
    const [dormitorioModal, setDormitorioModal] = useState<IFiltroItemOption[]>([]);
    const [valorMinimo, setValorMinimo] = useState('');
    const [valorMaximo, setValorMaximo] = useState('2000000000');
    const [descBusca, setDescBusca] = useState('');

    const [state, setState] = useState(false);

    useEffect(() => {        
        async function loadFilterData(): Promise<void> {
        
          //Estagio da Obra
          const { data: andamentoData } = await api.get<IEmpreendimentoAndamento[]>(
            'Empreendimento/andamentos',
          );
          setAndamentoModal(andamentoData.map(andamento => ({
            label: andamento.nome,
            value: andamento.id,
            }))
          );

          //Região
          if (estadoFiltro){
            const { data: regioesData } = await api.get<IEmpreendimentoRegiao[]>(
              `Cidades/regioes/:id_cidade`,
              { params: { id_cidade: idEstado === 26 ? 5242 : 3589 } },
            );
            setRegiaoModal(regioesData.map(regiao => ({
              label: regiao.nome,
              value: regiao.id,
              }))
            );  
          }

          //Dormitórios
          setDormitorioModal(
            [
                { label: '1', value: '1' },
                { label: '2', value: '2' },
                { label: '3', value: '3' },
                { label: '4+', value: '4' },
              ],
          );

        }
        loadFilterData();
    },[estadoFiltro]);
    
    useEffect(() =>{
      aplicarFiltros();
    }, [valorMinimo, valorMaximo,dormitorio, regiao, descBusca, andamento])

    const aplicarFiltros = useCallback((): void => {
      console.log('descBusca',descBusca);
      let precoMin = valorMinimo ? valorMinimo : '0';
      let precoMax = valorMaximo ? valorMaximo : '0';
      let preco = '';
      
      if (valorMaximo !== '2000000000' || valorMinimo !== '' )
      {
        preco = '{\"min\":' + precoMin + ',\"max\":' + precoMax +'}';
      }

      let filtro={
        aerea:'',
        bairros:'',
        dormitorios: '[' + dormitorio.map((dormitorio) => '\"' + dormitorio.value + '\"') + ']',
        estagio_obra: '[' + andamento.map((andamento) => andamento.value) + ']',
        preco: preco,
        regioes: '[' + regiao.map((regiao) => regiao.value) + ']',
        search: descBusca,
        tipo_produto: '[]'
      };
           
      doSearch(filtro, true, 1);
    }, [doSearch, valorMinimo, valorMaximo,dormitorio, regiao, descBusca, andamento]);



    //Inicio Funções do DropDown do Preço
    const handleButtonClick = () => {
        setState(!state);
    };

    const container = React.useRef<any>();

    useEffect(() => {
        const handleClickOutside = (event) => {
          try{
            if (!container.current.contains(event.target)) {
              setState(false);
            }  
          }
          catch(err){
            console.log(err);
            setState(false);
          }  
        };
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("click", handleClickOutside);
      }, [container]);
    //Fim Funções do DropDown do Preço
    
  return (
    <Container>        
        <ContainerMenu>   
          {estadoFiltro &&(
            <>
            <SeparadorOptionBusca>
              <InputFiltro
                type="text"
                name="buscaProd"
                placeholder="Buscar produto"
                required
                onChange={(e) => setDescBusca(e.target.value)}
              />
            </SeparadorOptionBusca> 
            <SeparadorOption>
              <TegraSelectDropDown 
                  label={"Estágio da obra"}
                  name={"estagio-obra"}
                  options={andamentoModal}
                  setItems={(items: IFiltroItemOption[]) =>
                  setAndamento(items)}
              />
            </SeparadorOption>
            <SeparadorOption>
              <TegraSelectDropDown
                  label={"Regiões"}
                  name={"regioes"}
                  options={regiaoModal}
                  setItems={(items: IFiltroItemOption[]) =>
                  setRegiao(items)}
              />
            </SeparadorOption>
            <SeparadorOption>
              <TegraSelectDropDown
                  label={"Dormitórios"}
                  name={"dormitorio"}
                  options={dormitorioModal}
                  setItems={(items: IFiltroItemOption[]) =>
                  setDormitorio(items)}
              />
            </SeparadorOption>
            <SeparadorOptionPreco>
              <ContainerButton  ref={container}>
                <ButtonDrop type="button" className="button" onClick={handleButtonClick}>
                  <div className="textoButton">Preço</div>   

                  <div className="buttonDropContainer">
                    <div className={state ? 'buttonDropOpen':'buttonDrop'} />
                  </div>          
                </ButtonDrop>
              
                  {state && (
                  <DropDownPreco>
                      <label>
                        Preço mínimo:
                        <input
                          type="number"
                          name="valMin"
                          className="input"
                          placeholder="R$"
                          defaultValue={valorMinimo}
                          required
                          onChange={(e) => setValorMinimo(e.target.value)}
                        />
                    </label>
                    <label>
                        Preço máximo:
                        <input
                          type="number"
                          name="valMax"
                          className="input"
                          placeholder="R$"
                          defaultValue={valorMaximo}                      
                          required
                          onChange={(e) => setValorMaximo(e.target.value)}
                        />
                      </label>

                  </DropDownPreco>
                  )}
              </ContainerButton>
            </SeparadorOptionPreco>
            <SeparadorOptionSearch>
              <ButtonSerch
                  onClick={aplicarFiltros}
              >
                <img src={searchIcon} />
              </ButtonSerch>
            </SeparadorOptionSearch>
          </>            
          )}    

          {!estadoFiltro &&(
            <>
            <SeparadorOptionBusca2>
              <InputFiltro
                  type="text"
                  name="buscaProd"                
                  placeholder="Buscar produto"
                  required
                  onChange={(e) => setDescBusca(e.target.value)}
              />
            </SeparadorOptionBusca2>  
            <SeparadorOptionPreco2>
              <ContainerButton  ref={container}>
                <ButtonDrop type="button" className="button" onClick={handleButtonClick}>
                  <div className="textoButton">Preço</div>   

                  <div className="buttonDropContainer">
                    <div className={state ? 'buttonDropOpen':'buttonDrop'} />
                  </div>          
                </ButtonDrop>
              
                  {state && (
                  <DropDownPreco>
                      <label>
                        Preço mínimo:
                        <input
                          type="number"
                          name="valMin"
                          className="input"
                          placeholder="R$"
                          defaultValue={valorMinimo}
                          required
                          onChange={(e) => setValorMinimo(e.target.value)}
                        />
                    </label>
                    <label>
                        Preço máximo:
                        <input
                          type="number"
                          name="valMax"
                          className="input"
                          placeholder="R$"
                          defaultValue={valorMaximo}                      
                          required
                          onChange={(e) => setValorMaximo(e.target.value)}
                        />
                      </label>

                  </DropDownPreco>
                  )}
              </ContainerButton>
            </SeparadorOptionPreco2>
            <SeparadorOptionSearch>
              <ButtonSerch
                  onClick={aplicarFiltros}
              >
                <img src={searchIcon} />
              </ButtonSerch>
            </SeparadorOptionSearch>
          </>            
          )}       
          <FilterActionsContainer>    
            <div className="FilterContent">
              <TegraButton isAction onClick={() => aplicarFiltros()}>
                Aplicar filtro
              </TegraButton>
            </div>    
          </FilterActionsContainer>       
        </ContainerMenu>
    </Container>
   );
};

export default TegraFiltroHorinzontal;
