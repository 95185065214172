import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import Home from '../Home';
import FrequentlyAsked from '../FrequentlyAsked';
import MyProfile from '../MyProfile';
import MyProfileCorretores from '../MyProfileCorretores';
import MyPage from '../MyPage';
import Products from '../Products';

// import Route from './route';
import ProductDetails from '../ProductDetails';
import PremiacoesEMeta from '../PremiacoesEMeta';
import PremiacoesEMetaDetalhes from '../PremiacoesEMetaDetalhes';
import Presentations from '../ProductMaterials/Pages/Presentations';
import Training from '../ProductMaterials/Pages/Training';
import TreinamentoProduto from '../ProductMaterials/Pages/TreinamentoProduto';
import TreinamentoDiversos from '../ProductMaterials/Pages/TreinamentoDiversos';
import Ferramentas from '../ProductMaterials/Pages/Ferramentas';
import CicloNegocio from '../ProductMaterials/Pages/CicloNegocio';
import IptuCondominio from '../ProductMaterials/Pages/IptuCondominio';
import Simulations from '../ProductMaterials/Pages/Simulations';
import Videos from '../ProductMaterials/Pages/Videos';
import VirtualTour from '../ProductMaterials/Pages/VirtualTour';
import Blueprints from '../ProductMaterials/Pages/Blueprints';
import FaqCompliance from '../FaqCompliance';
import Compliance from '../FaqCompliance/Compliance';
import Mapa from '../Mapa';
import Images from '../ProductMaterials/Pages/Images';
import MirrorsTables from '../ProductMaterials/Pages/MirrorsTables';
import Books from '../ProductMaterials/Pages/Books';
import TreinamentosDetalhes from '../TreinamentosDetalhes';
import CalendarioTrabalho from '../CalendarioTrabalho';
import CalendarioTrabalhoDetalhes from '../CalendarioTrabalhoDetalhes';
import Novidades from '../Novidades';
import NovidadesDetalhes from '../NovidadesDetalhes';
import PublicacaoLideranca from '../PublicacaoLideranca';
import PublicacaoLiderancaDetalhes from '../PublicacaoLiderancaDetalhes';
import EspacoGerente from '../EspacoGerente';
import PresencaPlantao from '../PresencaPlantao';
import RegistroVisita from '../RegistroVisita';
import Contacts from '../Contacts';
import Ranking from '../Ranking';
import MarketingEmail from '../ProductMaterials/Pages/MarketingEmail';
import RedesSociais from '../ProductMaterials/Pages/RedesSociais';
import PecasAnuncios from '../ProductMaterials/Pages/PecasAnuncios';
import Masterplan from '../ProductMaterials/Pages/Masterplan';
import OutrasImagens from '../ProductMaterials/Pages/OutrasImagens';
import MateriaisCorretor from '../ProductMaterials/Pages/MateriaisCorretor';
import Manuais from '../ProductMaterials/Pages/Manuais';
import Evento from '../Evento';
import EventosParcerias from '../EventosParcerias';

import DescriptiveMemo from '../ProductMaterials/Pages/DescriptiveMemo';
import MateriaisGerais from '../ProductMaterials/Pages/MateriaisGerais';
import IMInfo from '../ProductMaterials/Pages/IMInfo';
import EspelhosTabelaDetalhes from '../EspelhosTabelaDetalhes';
import MyDocumentsFeedback from '../MyDocumentsFeedback';
import Suggestions from '../Suggestions';
import BannerWrapper from '../../components/BannerWrapper';
import Relatorio from '../Relatorios';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dates/initialize';
import RelatorioAtividades from '../Relatorios/atividades';
import 'react-dates/lib/css/_datepicker.css';
import { useAuth } from '../../hooks/auth';
import TimeGigantes from '../TimeGigantes';

const RedirectToHome: React.FC = () => {
  return <Redirect to="/dashboard" />
}

const Dashboard: React.FC = () => {
  const { path } = useRouteMatch();
  const { user } = useAuth();
  return (
    <BannerWrapper>
      <ToastContainer />
      <Switch>
        <Route path={path} exact component={Home} />
        <Route
          path={`${path}/perguntas-frequentes`}
          component={user.acessos.perguntasFrequentes ? FrequentlyAsked : RedirectToHome}
        />
        <Route
          path={`${path}/perfil/meus-corretores`}
          component={MyProfileCorretores}
        />
        <Route path={`${path}/perfil/:tab`} component={MyProfile} />
        <Route path={`${path}/corretor/editar/:id`} component={MyProfile} />
        <Route 
          path={`${path}/minha-pagina`} 
          component={user.acessos.minhaPagina ? MyPage : RedirectToHome} 
        />
        <Route 
          path={`${path}/produtos`} 
          exact 
          component={user.acessos.produtos ? Products : RedirectToHome} />
        <Route
          path={`${path}/produtos/:uf/:cidade/:slug`}
          component={user.acessos.produtos ? ProductDetails : RedirectToHome}
        />
        <Route
          path={`${path}/premiacoes-e-metas`}
          exact
          component={PremiacoesEMeta}
        />
        <Route
          path={`${path}/premiacoes-e-metas/:slug`}
          component={PremiacoesEMetaDetalhes}
        />
        {/*
        <Route
          path={`${path}/materiais-de-produtos/treinamentos`}
          component={Training}
        />        
        <Route
          //path={`${path}/materiais-de-produtos/treinamentos`}
          path={`${path}/materiais-de-produtos/apresentacoes`}
          component={Presentations}
        />
      */}
        <Route
          path={`${path}/materiais-de-produtos/treinamento-de-produtos`}
          component={TreinamentoProduto}
        />    
        <Route
          path={`${path}/materiais-de-produtos/treinamento-diversos`}
          component={TreinamentoDiversos}
        />    
        <Route
          path={`${path}/materiais-de-produtos/ciclo-do-negocio`}
          component={CicloNegocio}
        />    
        <Route
          path={`${path}/materiais-de-produtos/ferramentas`}
          component={Ferramentas}
        />    

        <Route
          path={`${path}/materiais-de-produtos/iptu-e-condominio`}
          component={IptuCondominio}
        />
        <Route
          path={`${path}/materiais-de-produtos/simule-financiamento`}
          component={Simulations}
        />
        <Route
          path={`${path}/materiais-de-produtos/videos`}
          component={Videos}
        />
        <Route
          path={`${path}/materiais-de-produtos/tour-virtual`}
          component={VirtualTour}
        />
        <Route
          path={`${path}/materiais-de-produtos/plantas`}
          component={Blueprints}
        />
        <Route
          path={`${path}/faq-compliance`}
          component={FaqCompliance}
        />
        <Route
          path={`${path}/compliance`}
          component={Compliance}
        />
        <Route
          path={`${path}/mapa`}
          component={Mapa}
        />
        <Route
          path={`${path}/time-de-gigantes`}
          component={TimeGigantes}
        />
        <Route
          path={`${path}/materiais-de-produtos/imagens`}
          component={Images}
        />
        <Route
          path={`${path}/materiais-de-produtos/espelhos-e-tabelas`}
          component={MirrorsTables}
        />
        <Route
          //path={`${path}/materiais-de-produtos/apresentacoes`}
          path={`${path}/materiais-de-produtos/books`}
          component={Books}
        />
        <Route
          path={`${path}/materiais-de-produtos/email-marketing`}
          component={MarketingEmail}
        />
        {/*
        <Route
          path={`${path}/materiais-de-produtos/redes-sociais`}
          component={RedesSociais}
        />
        */}
        <Route
          path={`${path}/materiais-de-produtos/masterplan`}
          component={Masterplan}
        />
        <Route
          path={`${path}/materiais-de-produtos/pecas-anuncios`}
          component={PecasAnuncios}
        />
        <Route
          path={`${path}/materiais-de-produtos/outras-imagens`}
          component={OutrasImagens}
        />
        <Route
          path={`${path}/materiais-de-produtos/materiais-corretor`}
          component={MateriaisCorretor}
        />
        <Route
          path={`${path}/materiais-de-produtos/manuais`}
          component={Manuais}
        />
        <Route
          path={`${path}/materiais-de-produtos/memorial-descritivo`}
          component={DescriptiveMemo}
        />
        <Route
          path={`${path}/materiais-de-produtos/informacoes-de-im`}
          component={IMInfo}
        />
        {/*
        <Route
          path={`${path}/materiais-de-produtos/materiais-gerais`}
          component={MateriaisGerais}
        />
        */}
        <Route
          path={`${path}/ranking/:tab`}
          component={user.acessos.ranking ? Ranking : RedirectToHome}
        />
        <Route
          path={`${path}/relatorio/atividades`}
          component={user.acessos.relatorios ? RelatorioAtividades : RedirectToHome}
        />
        <Route
          path={`${path}/relatorio/:tab`}
          component={user.acessos.relatorios ? Relatorio : RedirectToHome}
        />
        <Route
          path={`${path}/treinamentos/:id`}
          component={TreinamentosDetalhes}
        />
        <Route
          path={`${path}/calendario-de-eventos`}
          exact
          component={CalendarioTrabalho}
        />
        <Route
          path={`${path}/calendario-de-eventos/data/:slug`}
          exact
          component={CalendarioTrabalho}
        />
        <Route
          path={`${path}/calendario-de-eventos/:slug`}
          component={CalendarioTrabalhoDetalhes}
        />
        <Route path={`${path}/novidades`} exact component={Novidades} />
        <Route path={`${path}/novidades/:slug`} component={NovidadesDetalhes} />
        <Route
          path={`${path}/lideranca`}
          exact
          component={user.acessos.lideranca ? PublicacaoLideranca : RedirectToHome}
        />
        <Route
          path={`${path}/lideranca/:slug`}
          component={user.acessos.lideranca ? PublicacaoLiderancaDetalhes : RedirectToHome}
        />
        <Route 
          path={`${path}/espaco-gerente`} 
          component={user.acessos.espacoGerente ? EspacoGerente : RedirectToHome} 
        />
        <Route
          path={`${path}/presenca-plantao`}
          component={user.acessos.presencaPlantao ? PresencaPlantao : RedirectToHome}
        />
        <Route
          path={`${path}/registro-visita`}
          component={user.acessos.registroVisita ? RegistroVisita : RedirectToHome}
        />
        <Route
          path={`${path}/evento`}
          component={user.acessos.evento ? Evento : RedirectToHome}
        />
        <Route
          path={`${path}/eventosParcerias`}
          component={user.acessos.eventosParcerias ? EventosParcerias : RedirectToHome}
        />
        <Route 
          path={`${path}/contatos/:tab`} 
          component={user.acessos.contatos ? Contacts : RedirectToHome} 
        />
        <Route
          path={`${path}/espelhos-e-tabelas/:id`}
          component={EspelhosTabelaDetalhes}
        />

        {/* <Route path={`${path}/espelhos-e-tabelas`} component={EspelhosTabela} /> */}
        <Route
          path={`${path}/meus-documentos/feedback`}
          exact
          component={MyDocumentsFeedback}
        />
        <Route 
          path={`${path}/sugestoes`} 
          exact 
          component={user.acessos.sugestoes ? Suggestions : RedirectToHome} />
      </Switch>
    </BannerWrapper>
  );
};

export default Dashboard;
