import React, {
    useState,
    useRef,
    useEffect,
  } from 'react';

import { Container as GridContainer, Col } from 'react-grid-system';
import { Form, FormHandles, SubmitHandler } from '@unform/core';
import { useLoader } from '../../../hooks/loader';


import {
    Container,
    Content,
    ProfileBox,
    ProfileFilesBox,
    RowDoc,
    DadosItem,
    DadosItemContent,
    ErrorsContainer,
    DadosList,
    ProfileTabs,
  } from './style';
  
import { useParams, useHistory } from 'react-router-dom';
import TegraDocumentInput from '../../../components/TegraDocumentInput';
import api from '../../../services/api';

export interface ProfileFormData {
    id_parceiro: number;
    txt_tipoParceiro: string;
    id_tipoParceiro: number;
    id_tipoCorretor: number;
    tx_razaoSocial: string;
    tx_nome: string;
    nu_creci: number;
    tx_creci: string;
    tx_email: string;
    tx_uf: string;
    tx_regional: string;
    id_situacao: number;
    tx_foto: string;
    tx_cidade: string;
    tx_bairro: string;
    tx_cep: string;
    tx_logradouro: string;
    tx_numero: string;
    tx_cpfcnpj: string;
    tx_telefone: string;
    tx_celular: string;
    tx_situacao: string;    
    tx_responsavel: string;
    tx_responsavelEmail: string;
    tx_responsavelTelefone: string;
    tx_complemento: string;
  }
  
  interface ProfileData {
    name: string;
    value: any;
    type: string;
    fieldName: string;
    mask?: string;
    tipo?: number | null;
  }
  
  const getDocumentType = (str: string): string => {
    try {
      const val = str.replace(/[^0-9]/gi, '');
  
      return val.length === 11 ? 'CPF' : 'CNPJ';
    } catch (e) {
      return 'CPF';
    }
  };
  

  interface CorretorProps {
    dados?: ProfileFormData;
  }
  

  const mockData = [
    {
      name: 'Regional',
      value: '',
      type: 'select',
      fieldName: 'regional',
    },
    { name: 'Gerente', value: '', type: 'select', fieldName: 'gerente' },
    {
      name: 'CNPJ',
      value: '',
      type: 'text',
      fieldName: 'cnpj',
      mask: '999.999.999-99',
    },
    { name: 'CRECI', value: '', type: 'text', fieldName: 'creci' },
    {
      name: 'Razão Social',
      value: '',
      type: 'text',
      fieldName: 'razao_social',
      tipo: 1,
    },
    {
      name: 'Responsável',
      value: '',
      type: 'text',
      fieldName: 'responsavel',
      tipo: 1,
    },
    {
      name: 'E-mail do responsável',
      value: '',
      type: 'email',
      fieldName: 'responsavel_email',
      tipo: 1,
    },
    {
      name: 'Telefone do responsável',
      value: '',
      type: 'tel',
      mask: '99999-9999',
      fieldName: 'responsavel_tel',
      tipo: 1,
    },
    {
      name: 'Celular do responsável',
      value: '',
      type: 'tel',
      mask: '99999-9999',
      fieldName: 'responsavel_cel',
      tipo: 1,
    },
    {
      name: 'Logradouro',
      value: '',
      type: 'text',
      fieldName: 'logradouro',
    },
    { name: 'Número', value: '', type: 'number', fieldName: 'numero' },
    {
      name: 'Complemento',
      value: '',
      type: 'text',
      fieldName: 'complemento',
    },
    { name: 'Bairro', value: '', type: 'text', fieldName: 'bairro' },
    {
      name: 'CEP',
      value: '',
      type: 'text',
      fieldName: 'cep',
      mask: '9999999-999',
    },
    { name: 'Estado', value: '', type: 'select', fieldName: 'estado' },
    { name: 'Cidade', value: '', type: 'select', fieldName: 'cidade' },
    {
      name: 'Status',
      value: '',
      type: 'select',
      fieldName: 'status',
    },
    {
      name: 'CNH, CPF ou RG',
      value: '',
      type: 'file',
      fieldName: 'identificacao_doc',
    },
    {
      name: 'CRECI',
      value: '',
      type: 'file',
      fieldName: 'creci_doc',
    },
    {
      name: 'Comprovante de residência',
      value: '',
      type: 'file',
      fieldName: 'comprovante_doc',
    },
  ];
  
  interface Documento {
    id: number;
    id_parceiro: number;
    tx_nomeOriginal: string;
    tx_nomeArquivo: string;
    tx_caminho: string;
    nu_tipoDocumento: number;
    dt_cadastro: Date;
  }
  
  interface ProfileFilesFormData {
    identificacao_doc: File;
    comprovante_doc: File;
    creci_doc: File;
  }
  
  const handleSubmitProfileFiles: SubmitHandler<ProfileFilesFormData> = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data,
  ): void => {
    // Atualizar dados
  };


const DadosCorretor: React.FC<CorretorProps> = ({ dados }) => {
    const tipo = dados?.txt_tipoParceiro === 'Corretor Tegra Vendas'? -1: 
            dados?.id_tipoParceiro === undefined ? 0 : dados?.id_tipoParceiro;

    const { setLoaderActive } = useLoader();
    const { tab: tabParam, id } : any = useParams();
    const [tab, setTab] = useState<string>(tabParam || 'meus-dados');
    const [errors, setErrors] = useState<string[]>([]);
    const [values, setValues] = useState<ProfileData[]>(mockData);
    const [files, setFiles] = useState<Documento[]>([]);
    const formRefProfileFiles = useRef<FormHandles>(null);

     
    useEffect(() => {        
        async function loadData(): Promise<void> {
        setLoaderActive(true);
          try {
            if (tipo === 0) {
                const { data: documentos } = await api.get<Documento[]>(
              `DocumentosParceiro/${dados?.id_parceiro}`,
                );        
                setFiles(documentos);
            }         
            const newValues: ProfileData[] = [

              {
                name: getDocumentType(dados?.tx_cpfcnpj === undefined ? '123456789': dados?.tx_cpfcnpj ),
                value: dados?.tx_cpfcnpj,
                type: 'text',
                fieldName: 'tx_documento',
                mask: '999.999.999-99',
              },              
              {
                name: 'E-mail',
                value: dados?.tx_email,
                type: 'email',
                fieldName: 'tx_email',
                tipo: 2,
              },   
              {
                name: 'E-mail',
                value: dados?.tx_email,
                type: 'email',
                fieldName: 'tx_email',
                tipo: -1,
              },                        
              {
                name: 'Situação',
                value: dados?.tx_situacao,
                type: 'text',
                fieldName: 'id_status',
              },
              {
                name: 'CRECI',
                value: dados?.tx_creci,
                type: 'text',
                fieldName: 'tx_creci',
              },
              {
                name: 'Regional',
                value: dados?.tx_regional,
                type: 'text',
                fieldName: 'id_regional',
              },
              {
                name: 'Responsável',
                value: dados?.tx_responsavel,
                type: 'text',
                fieldName: 'tx_responsavel',
                tipo: 1,
              },             
              {
                name: 'E-mail do responsável',
                value: dados?.tx_responsavelEmail,
                type: 'email',
                fieldName: 'tx_responsavelEmail',
                tipo: 1,              
              },                           
              {
                name: 'Telefone do responsável',
                value: dados?.tx_responsavelTelefone,
                type: 'tel',
                mask: '99999-9999',
                fieldName: 'tx_responsavelTelefone',
                tipo: 1,
              },
              {
                name: 'Celular do responsável',
                value: dados?.tx_celular,
                type: 'tel',
                mask: '99999-9999',
                fieldName: 'tx_celular',
              },                          
              {
                name: 'Telefone',
                value: dados?.tx_telefone,
                type: 'tel',
                mask: '99999-9999',
                fieldName: 'tx_celular',
                tipo: 2,
              },                          
              {
                name: 'Estado',
                value: dados?.tx_uf,
                type: 'text',
                fieldName: 'estado',
                tipo: 0,
              },
              {
                name: 'Cidade',
                value: dados?.tx_cidade,
                type: 'text',
                fieldName: 'id_cidade',
                tipo: 0,
              },
              {
                name: 'Bairro',
                value: dados?.tx_bairro,
                type: 'text',
                fieldName: 'tx_bairro',
                tipo: 0,
              },
              {
                name: 'Logradouro',
                value: dados?.tx_logradouro,
                type: 'text',
                fieldName: 'tx_logradouro',
                tipo: 0,
              },
              {
                name: 'Número',
                value: dados?.tx_numero,
                type: 'number',
                fieldName: 'tx_numero',
                tipo: 0,
              },
              {
                name: 'Complemento',
                value: dados?.tx_complemento,
                type: 'text',
                fieldName: 'complemento',
              },
              {
                name: 'CEP',
                value: dados?.tx_cep,
                type: 'text',
                fieldName: 'tx_cep',
                mask: '99999-999',
                tipo: 0,
              },
            ];
            setValues(newValues);    
          } finally {
            setLoaderActive(false);
          }
        };
        loadData();
      }, [dados]);
      
    return(
        <Container>
        <Content>
          {tipo > 0 && (
            <ProfileTabs>
              <span
                onClick={() => setTab('meus-dados')}
                className={tab === 'meus-dados' ? 'selected' : ''}
              >
                Dados
              </span>              
              <span
                onClick={() => setTab('meus-documentos')}
                className={tab === 'meus-documentos' ? 'selected' : ''}
              >
                Documentos
              </span>
            </ProfileTabs>
          )}
          {errors.length >= 1 && (
            <ErrorsContainer>
              {errors.map(error => (
                <p>{error}</p>
              ))}
            </ErrorsContainer>
          )}
          {tab === 'meus-dados' && (
            <ProfileBox>
                <DadosList>
                {values
                  .filter(
                    dado =>
                      dado.type !== 'file' &&
                      (dado.tipo === undefined ||
                        dado.tipo === tipo ||
                        (dado.tipo === 0 && tipo >= 0)),
                  )
                  .map(dado => (
                    <DadosItem key={dado.name.trim()}>
                      <DadosItemContent>
                        <p>{dado.name}</p>
                        <div>
                          {(
                            <strong>
                              {dado.value}
                            </strong>
                          )}

                        </div>
                      </DadosItemContent>
                    </DadosItem>
                  ))}
              </DadosList>
            </ProfileBox>
          )}
        {tab === 'meus-documentos' && (
          <ProfileFilesBox>
            <Form ref={formRefProfileFiles} onSubmit={handleSubmitProfileFiles}>
              <GridContainer
                style={{
                  padding: 0,
                  marginLeft: '0px',
                  marginRight: '0px',
                  maxWidth: '100%',
                }}
              >
                { dados?.id_tipoParceiro == 1 && (
                  <RowDoc>
                    <Col sm={12}>
                      <TegraDocumentInput
                        enablePreview={false}
                        parceiro={dados?.id_parceiro}
                        canEdit={false}
                        sendButton={false}
                        setErrors={setErrors}
                        formRef={formRefProfileFiles}
                        label="Contrato Social"
                        name="contrato_social"
                        filename={
                          files.find(f => f.nu_tipoDocumento === 3)
                            ?.tx_nomeOriginal
                        }
                      />
                      <div style={{marginTop:'10px'}}></div>
                      <TegraDocumentInput
                        enablePreview={false}
                        parceiro={dados?.id_parceiro}
                        canEdit={false}
                        sendButton={false}
                        setErrors={setErrors}
                        formRef={formRefProfileFiles}
                        label=""
                        name="cartao_cnpj"
                        filename={
                          files.find(f => f.nu_tipoDocumento === 4)
                            ?.tx_nomeOriginal
                        }
                      />                      
                    </Col>
                  </RowDoc>
                )}
                <RowDoc>
                  <Col sm={12}>
                    <TegraDocumentInput
                      enablePreview={false}
                      parceiro={dados?.id_parceiro}
                      canEdit={false}
                      sendButton={false}
                      setErrors={setErrors}
                      formRef={formRefProfileFiles}
                      label={dados?.id_tipoParceiro == 1 ? "CNH, CPF ou RG dos sócios" : "CNH, CPF ou RG"}
                      name="identificacao_doc"
                      filename={
                        files.find(f => f.nu_tipoDocumento === 0)
                          ?.tx_nomeOriginal
                      }
                    />
                  </Col>
                </RowDoc>
                <RowDoc>
                  <Col sm={12}>
                    <TegraDocumentInput
                      enablePreview={false}
                      parceiro={dados?.id_parceiro}
                      canEdit={false}
                      sendButton={false}
                      setErrors={setErrors}
                      formRef={formRefProfileFiles}
                      label={dados?.id_tipoParceiro == 1 ? "Certidão do CRECI Atualizada" : "CRECI"}
                      name="creci_doc"
                      filename={
                        files.find(f => f.nu_tipoDocumento === 1)
                          ?.tx_nomeOriginal
                      }
                    />
                  </Col>
                </RowDoc>
                <RowDoc>
                  <Col sm={12}>
                    <TegraDocumentInput
                      enablePreview={false}
                      parceiro={dados?.id_parceiro}
                      canEdit={false}
                      sendButton={false}
                      setErrors={setErrors}
                      formRef={formRefProfileFiles}
                      label={dados?.id_tipoParceiro == 1 ? "Comprovante de endereço atualizado com máximo de 03 meses" : "Comprovante de residência"}
                      name="comp_residencia_doc"
                      filename={
                        files.find(f => f.nu_tipoDocumento === 2)
                          ?.tx_nomeOriginal
                      }
                    />
                  </Col>
                </RowDoc>
              </GridContainer>
            </Form>
          </ProfileFilesBox>
        )}
        </Content>
      </Container>    
    );
};

export default DadosCorretor;

  