import React, { useState, useEffect } from 'react';
import { Container } from './styles';

const Mapa: React.FC = () => {
    const [pageMapa, SetPageMapa] = useState("");
    //const [pageHeight, SetPageHeight] = useState("2200px");
    const [pageHeight, SetPageHeight] = useState("1000px");
    const [pageWidth, SetPageWidth] = useState(0);

    useEffect(() => {
        if (window.innerWidth != pageWidth){
            handleResize();
        }
        function handleResize() { 
            if (window.location.hostname.toLowerCase().indexOf('localhost') === 0 || window.location.hostname.toLowerCase().indexOf('qa') === 0){
                SetPageMapa("https://tegra-mapacorretor-hom.pages.dev/");
            }
            else{
                SetPageMapa("https://tegra-mapacorretor.pages.dev/");
            }            
            

            if (window.innerWidth >= 1150){
                //SetPageHeight("2200px");
                SetPageHeight("800px");
            }  
            SetPageWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        //recebe do frame a altura no redimensionamento mobile
        /*
        window.addEventListener('message', function(e) {
            // message that was passed from iframe page
            if(e.data.hasOwnProperty('height')) {
                let message = e.data;
                SetPageHeight((message.height + 60) +"px");
            }
        });
        */
    }, [pageWidth, pageMapa, pageHeight]);

      return (
        <Container>
                <iframe 
                    id='iframe'
                    src={pageMapa} 
                    width="100%" 
                    height={pageHeight}
                    data-gtm-yt-inspected-7200357_164="true"
                ></iframe>
        </Container>
    );
};
  
  export default Mapa;