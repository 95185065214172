import React, { useState, useCallback, useRef, useEffect } from 'react';


import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  Container as GridContainer,
  Row,
  Col,
  Hidden,
  Visible,
} from 'react-grid-system';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Content,
  SearchBox,
  FiltrarContainer,
  LoadMore,
  DivDropDown,
} from './styles';
import TegraOpaqueHero from '../../components/TegraOpaqueHero';
import TegraDivider from '../../components/TegraDivider';
import TegraFiltro from '../../components/TegraFiltro';
import filtrarIcon from '../../assets/tegra-icon-filtrar.svg';
import searchIcon from '../../assets/tegra-icon-search.svg';
import TegraInput from '../../components/TegraInput';
import TegraProdutosList from '../../components/TegraProdutosList';

import TegraSelectDropDown from '../../components/TegraSelectDropDown';
import TegraFiltroHorizontal from '../../components/TegraFiltroHorizontal';

import api from '../../services/api';
import { useLoader } from '../../hooks/loader';
import TegraButton from '../../components/TegraButton';
import { useAuth } from '../../hooks/auth';
import { reduceHooks } from 'react-table';
import { relative } from 'path';
import { backgrounds } from 'polished';

interface IProduto {
  localidade: {
    bairro: string;
    cidade: string;
  };
  nome: string;
  titulo: string;
  destaque: boolean;
  metragem: string;
  informacoes: string;
  id: number;
  imagem: any;
  preco: number;
  regiao: string;
  estagio_obra: string;
  tipo: number;
  aerea: number;
}

export interface IFiltroFormData {
  tipo_produto: string;
  estagio_obra: string;
  regioes: string;
  search: string;
  bairros: string;
  dormitorios: string;
  aerea: string;
  preco: string;
}

export interface IFiltroItemOption {
  label: string;
  value: string;
}

export interface IFiltroItemOptionRange {
  label_from: string;
  label_to: string;
}

export interface IFiltroItem {
  label: string;
  name: string;
  type: 'checkbox' | 'multiselect' | 'range';
  options?: IFiltroItemOption[] | IFiltroItemOptionRange;
}

// const mockProdutos: IProduto[] = [
//   {
//     localidade: { bairro: 'Jardins', cidade: 'São Paulo' },
//     titulo: 'Zahle Jardins',
//     metragem: '28m<sup>2</sup> a 44m<sup>2</sup>',
//     informacoes: '3 suítes ou 4 dormitórios',
//     id: '1',
//     imagem: produtoBg,
//     preco: 2000,
//     estagio_obra: 'construcao',
//     aerea: 2000,
//     destaque: true,
//     regiao: 'Guarulhos',
//   },
//   {
//     localidade: { bairro: 'Jardins', cidade: 'São Paulo' },
//     titulo: 'Zahle Jardins',
//     metragem: '28m<sup>2</sup> a 44m<sup>2</sup>',
//     informacoes: '3 suítes ou 4 dormitórios',
//     id: '2',
//     imagem: produtoBg,
//     preco: 2000,
//     estagio_obra: 'construcao',
//     aerea: 2000,
//     destaque: true,
//     regiao: 'Guarulhos',
//   },
//   {
//     localidade: { bairro: 'Jardins', cidade: 'São Paulo' },
//     titulo: 'Zahle Jardins',
//     metragem: '28m<sup>2</sup> a 44m<sup>2</sup>',
//     informacoes: '3 suítes ou 4 dormitórios',
//     id: '3',
//     imagem: produtoBg,
//     preco: 2000,
//     estagio_obra: 'construcao',
//     aerea: 2000,
//     destaque: true,
//     regiao: 'Guarulhos',
//   },
// ];

export interface IEmpreendimento {
  id: number;
  nome: string;

  bairro: string;

  cidade: string;

  metragemDe: number;
  metragemAte: number;

  isExibidoComoOportunidade: true;

  metragemDescricao: string;
  valor: number;
  valorAproximado: number;
  dormitoriosDe: string;
  dormitoriosAte: string;
  dormitoriosDescricao: string;
  imagemBuscaPequenaLoja: string;
  imagemBuscaPequena: string;
  imagemBuscaMedia: string;
  imagemBuscaGrande: string;

  descricao: string;
  empreendimentoTipoId: number;
  empreendimentoTipo: string;
  empreendimentoAndamentoId: number;
  empreendimentoAndamento: string;

  chamada: string;

  distancia: number;
  recomendadoEstado: true;

  metragemDeLoja: number;
  metragemAteLoja: number;

  slug?: string;
  uf?: string;
  isBanner?: boolean;
}

const Products: React.FC = () => {
  // const { setLoaderActive } = useLoader();
  const { user } = useAuth();
  const [filtroShow, setFiltroShow] = useState(false);
  const [produtos, setProdutos] = useState<IProduto[]>([]);
  const history = useHistory();
  const [lastSearch, setLastSearch] = useState<IFiltroFormData>(
    {} as IFiltroFormData,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const filtroFormRef = useRef<FormHandles>(null);

  const [oper, setOper] = useState<IFiltroItemOption[]>([]);

  const doSearch = useCallback(async (
    filtro?: IFiltroFormData,
    force = false,
    page: number | undefined = undefined,
  ): Promise<void> => {
    if (!force && JSON.stringify(filtro) === JSON.stringify(lastSearch)) return;
    const data: IFiltroFormData = filtro || ({} as IFiltroFormData);
    const { search } = data;


    setLastSearch(data);
    const pagina = page || currentPage;
    const params = new URLSearchParams();
    params.append('nu_pagina', pagina.toString());
    params.append('nu_quantidade', '12');
    params.append('id_regional', user.idRegional.toString());
    
    if (
      data.aerea ||
      data.preco ||
      data.tipo_produto ||
      data.estagio_obra ||
      data.regioes ||
      data.bairros ||
      data.dormitorios ||
      search
    ) {
      if (data.aerea !== '') {
        const area = JSON.parse(data.aerea as string);
        params.append('nu_areaDe', area.min || 0);
        params.append('nu_areaAte', area.max || 999999);
      }

      if (data.preco !== '') {
        const preco = JSON.parse(data.preco as string);
        params.append('nu_precoDe', preco.min || 0);
        params.append('nu_precoAte', preco.max || 999999);
      }
      if (
        data.tipo_produto !== '' &&
        JSON.parse(data.tipo_produto).length !== 0
      ) {
        JSON.parse(data.tipo_produto).forEach((item: string) =>
          params.append('id_tipo', item),
        );
      }
      if (
        data.estagio_obra !== '' &&
        JSON.parse(data.estagio_obra).length !== 0
      ) {
        JSON.parse(data.estagio_obra).forEach((item: string) =>
          params.append('id_andamento', item),
        );
      }
      if (data.regioes !== '' && JSON.parse(data.regioes).length !== 0) {
        JSON.parse(data.regioes).forEach((item: string) =>
          params.append('regioes', item),
        );
      }
      if (data.bairros !== '' && JSON.parse(data.bairros).length !== 0) {
        JSON.parse(data.bairros).forEach((item: string) =>
          params.append('bairros', item),
        );
      }
      if (
        data.dormitorios !== '' &&
        JSON.parse(data.dormitorios).length !== 0
      ) {
        JSON.parse(data.dormitorios).forEach((item: string) =>
          params.append('dormitorios', item),
        );
      }

      if (search) {
        params.append('pesquisa', search);
      }
    }

    //setLoaderActive(true);
    const response = await api.get('Empreendimento', {
      params,
    });

    const { items, paginas } = response.data;
    let newProdutos = pagina === 1 ? [] : produtos;
    newProdutos = newProdutos.concat(
      items.map(
        (item: IEmpreendimento) =>
          ({
            nome: item.nome,
            localidade: {
              bairro: item.bairro,
              cidade: item.cidade,
              uf: item.uf,
            },
            id: item.id,
            metragem: item.metragemDescricao,
            aerea: item.distancia,
            destaque: true,
            estagio_obra: item.empreendimentoAndamento,
            imagem: item.imagemBuscaPequena,
            preco: item.valor,
            titulo: item.nome,
            regiao: '',
            informacoes: '',
            tipo: item.empreendimentoAndamentoId,
            slug: item.slug,
          } as IProduto),
      ),
    );
    if (pagina === 1){
      setProdutos([]);
    }
    
    setProdutos([...newProdutos]);
    // setLoaderActive(false);
    setTotalPages(paginas);
    if (page !== currentPage) setCurrentPage(page || 1);
    
  },[produtos, totalPages, currentPage, lastSearch]);

  const handleLoadMore = useCallback(() => {
    doSearch(lastSearch, true, currentPage + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [produtos, lastSearch, currentPage, doSearch]);

  const searchAPIDebounced = AwesomeDebouncePromise(
    async (data: IFiltroFormData) => {
      await doSearch(data);
      setCurrentPage(1);
    },
    600,
  );

  const handleFiltroFormSubmit = useCallback(
    (data): void => {
      searchAPIDebounced(data);
    },
    [searchAPIDebounced],
  );

  const handleFiltroShow = useCallback((shouldShow: boolean) => {
    setFiltroShow(shouldShow);
  }, []);

  useEffect(() => {
    doSearch();
  }, []);

  const goToProduto = useCallback(
    url => {
      history.push(url);
    },
    [history],
  );


  return (
    <Container>
      <TegraOpaqueHero
        title="Produtos"
        description="Conheça todos os empreendimentos Tegra e acesse os materiais necessários para sua venda."
      />
      <TegraFiltroHorizontal
        idEstado={user.idEstado}
        doSearch={doSearch}
      />

      <Content>
        <GridContainer
          className="grid-container"
          style={{
            padding: 0,
            marginLeft: '0px',
            maxWidth: '100%',
            marginRight: '0px',
          }}
        >
          <Row style={{ marginRight: '0px' }}>
            {/*
            <Col sm={12} md={4} xl={4} xxl={3}>
              <Form ref={filtroFormRef} onSubmit={handleFiltroFormSubmit}>
                {filtroShow && (
                  <TegraFiltro
                    filtroFormRef={filtroFormRef}
                    doSearch={doSearch}
                    handleFiltroShow={handleFiltroShow}
                    idEstado={user.idEstado}
                  />
                )}               
                <SearchBox>
                  <Hidden sm={false} md xl xxl lg>
                    <FiltrarContainer>
                      <button
                        type="submit"
                        onClick={() => handleFiltroShow(true)}
                      >
                        Filtrar
                        <img src={filtrarIcon} alt="Filtrar" />
                      </button>
                    </FiltrarContainer>
                    <TegraDivider axis="x" height={1} color="#ADADAB" />
                  </Hidden>
                  <TegraInput
                    label="Buscar produto"
                    name="search"
                    formRef={filtroFormRef}
                    icon={searchIcon}
                    realTime
                  />
                </SearchBox>
                <Visible sm={false} md xl xxl lg>
                  <TegraFiltro
                    doSearch={doSearch}
                    filtroFormRef={filtroFormRef}
                    handleFiltroShow={handleFiltroShow}
                    idEstado={user.idEstado}
                  />
                </Visible>
              </Form>
            </Col>
            */}
            <Col sm={12} md={12} xl={12} style={{ padding: 0 }}>
              <TegraProdutosList items={produtos} goToProduto={goToProduto} rodape={false} />
              {totalPages > currentPage && (
                <LoadMore>
                  <TegraButton isAction onClick={handleLoadMore}>
                    Carregar mais
                  </TegraButton>
                </LoadMore>
              )}
            </Col>
          </Row>
        </GridContainer>
      </Content>
    </Container>
  );
};

export default Products;
