/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/ban-types */
import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
  RefObject,
  MouseEvent,
} from 'react';
import { useField, FormHandles } from '@unform/core';
import api, { baseURL } from '../../services/api';

import { Container } from './styles';
import uploadIcon from '../../assets/tegra-upload-icon.svg';
import editIcon from '../../assets/tegra-icon-edit.svg';
import ajaxLoading from '../../assets/ajax-loader-white.gif';

interface Props {
  name: string;
  parceiro?: number;
  setErrors?: Function;
  filename?: string;
  canEdit: boolean;
  label: string;
  formRef: RefObject<FormHandles>;
  sendButton: boolean;
  enablePreview: boolean;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const TegraDocumentInput: React.FC<InputProps> = ({
  name,
  parceiro,
  formRef,
  setErrors,
  filename,
  label,
  canEdit,
  sendButton,
  enablePreview,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, registerField } = useField(name);
  const [tipoDocumento] = useState(() => {
    let t = 0;
    switch (fieldName) {
      case 'identificacao_doc':
        t = 0;
        break;
      case 'creci_doc':
        t = 1;
        break;
      case 'comp_residencia_doc':
        t = 2;
        break;
      case 'contrato_social':
        t = 3;
        break;
      case 'cartao_cnpj':
        t = 4;
        break;        
      default:
        t = 0;
        break;
    }
    return t;
  });
  const [previewOut, setPreviewOut] = useState(enablePreview);
  const [preview, setPreview] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePreview = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];

      if (!file) {
        setPreview('');
        return;
      }

      if (!formRef.current) {
        throw new Error('Sem form');
      }

      setErrors && setErrors([]);
      setLoading(true);
      const multipart = new FormData();
      multipart.append('documento', file);
      api
        .post(
          `DocumentosParceiro/${parceiro}?tipoDocumento=${tipoDocumento}`,
          multipart,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          },
        )
        .then(res => {
          setLoading(false);
          setPreview(res.data.tx_nomeOriginal);              
        })
        .catch(err => {
          setLoading(false);
          if (err.response) {
            const { erro } = err.response.data;

            const validationErrors: string[] = [];

            validationErrors.push(erro);

            setErrors && setErrors(validationErrors);
          } else {
            setErrors && setErrors(['Arquivo inválido']);
          }
        });
    },
    [formRef, parceiro, setErrors, tipoDocumento],
  );

  const handleCancel = useCallback(() => {
    if (!inputRef.current) {
      throw new Error('No ref');
    }

    inputRef.current.value = '';
    setPreview('');
  }, [inputRef]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        ref.value = '';
        setPreview('');
      },
      setValue(_: HTMLInputElement, value: string) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    setPreview(filename || '');
  }, [filename]);

  const download = (e: MouseEvent): void => {
    e.preventDefault();
    api
      .get(
        /*`${baseURL}DocumentosParceiro/download/${parceiro}/${preview.replace(/[^0-9a-z]/gi, '')}/${tipoDocumento}`,*/
        `DocumentosParceiro/download/${parceiro}/${preview.replace(/[^0-9a-z./-]/gi, '')}/${tipoDocumento}`,
        {
          responseType: 'blob',
        },
      )
      .then(response => {
        const nav = (window.navigator as any);        
        if (nav.msSaveOrOpenBlob) {
          // IE11
          nav.msSaveOrOpenBlob(new Blob([response.data]), preview);
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', preview);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(url);
          }, 100);
        }        
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  return (
    <Container inputHasValue={!!preview}>
      <div>
        <p>{label}</p>
        <div>
          {preview && (
            <>
              <strong>{preview}</strong>
              {!previewOut && (
                <a href={preview} onClick={download}>
                  Visualizar
                </a>
              )
              }
            </>
          )}
        </div>
      </div>
      <div className="upload-btn-wrapper">
        {!preview && !loading && sendButton && (
          <>
            <button type="button" className="uploadBtn">
              <span>Enviar arquivo</span>
              <img src={uploadIcon} alt="Upload" />
            </button>
          </>
        )}
        {loading && <img src={ajaxLoading} alt="Carregando" />}
        <input type="file" ref={inputRef} onChange={handlePreview} {...rest} />
      </div>
      {preview && canEdit && !previewOut &&(
        <button type="button" className="cancelBtn" onClick={handleCancel}>
          Alterar
          <img src={editIcon} alt="editar arquivo" />
        </button>
      )}
    </Container>
  );
};

export default TegraDocumentInput;
